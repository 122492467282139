import { useState } from "react";
import { Controller } from "react-hook-form";
import { Button, Chip } from "@mui/material";
import FormDropdown from "../../components/FormDropdown";
import ErrorList from "../ErrorList";
import { useUserClaimForm, UserClaimFormValues } from "./UserClaimFormValues";
import { giggedClientUserClaimOptions, AddGiggedClientUserClaimDto, UserClaimDto, userClaimTypes } from "../../api/models/user";

export type UserClaimFormProps = {
    onSubmit: (dto: AddGiggedClientUserClaimDto) => Promise<void>;
    onRemove: (claimTypeId: number) => Promise<void>
    isLoading: boolean;
    giggedClientId: string;
    claims: UserClaimDto[]
};

const UserClaimForm = ({
    onSubmit,
    onRemove,
    isLoading,
    giggedClientId,
    claims
}: UserClaimFormProps) => {
    const [errors, setErrors] = useState<string[]>([]);
    const methods = useUserClaimForm();

    const handleSubmit = async (values: UserClaimFormValues) => {
        setErrors([]);

        const dto: AddGiggedClientUserClaimDto = {
            giggedClientId,
            claimTypeId: values.claimTypeId,
        };

        try {
            await onSubmit(dto);
            methods.reset();
        } catch (error) {
            setErrors(["An error occurred while adding the claim."]);
        }
    };

    const isMaxClaimsSelected = 
        claims.length === 2 && !claims.some(claim => claim.claimTypeId === userClaimTypes.admin) || 
        claims.length === 3 && claims.some(claim => claim.claimTypeId === userClaimTypes.admin);

    // if there are 2 claims and one of them isn't admin, max claims selected

    return (
        <div className="space-y-4">
            <form
                noValidate
                className="flex gap-6 flex-wrap md:flex-nowrap md:items-start"
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                <div className="flex gap-6 flex-wrap w-full md:flex-nowrap">
                    <div className="w-full">
                        <Controller
                            name="claimTypeId"
                            control={methods.control}
                            render={({ field: { value, onChange } }) => (
                                <FormDropdown
                                    options={giggedClientUserClaimOptions}
                                    error={methods.formState.errors.claimTypeId}
                                    value={value}
                                    onChange={onChange}
                                    disabled={isLoading || isMaxClaimsSelected}
                                />
                            )}
                        />
                    </div>
                </div>
                <Button
                    className="self-center w-full md:mt-auto md:w-auto"
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    disabled={isLoading || isMaxClaimsSelected}
                >
                    Add
                </Button>
            </form>

            <ErrorList errors={errors} />

            {isLoading ? (
                <div className="!mt-4">Loading...</div>
            ) : (
                claims.filter(chip => chip.claimTypeId !== userClaimTypes.admin).map(chip =>
                    <Chip
                        key={chip.claimTypeId}
                        label={chip.claimTypeName}
                        onDelete={chip.claimTypeId !== userClaimTypes.default ? () => onRemove(chip.claimTypeId) : undefined}
                        sx={{ marginRight: 1, marginBottom: 1 }}
                        disabled={isLoading}
                        variant="outlined"
                        color="secondary"
                    />
                )
            )}
        </div>
    );
};

export default UserClaimForm;