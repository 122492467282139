import { useEffect } from "react";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Link } from "react-router-dom";

import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import { IconButton, TableCell } from "@mui/material";
import { useSkills } from "../../api/skills";
import FilterLinkCell from "./FilterLinkCell";
import { useAppPaths } from "../../Routes";
import { useOrganizationConfig } from "../../api/organization";

export type SkillsTableProps = {
    searchTerm?: string
}

const SkillsTable = ({ searchTerm }: SkillsTableProps) => {
    const skills = useSkills();
    const appPaths = useAppPaths();
    const { talentTerminology } = useOrganizationConfig();

    const setSearchTerm = skills.setSearchTerm;

    const handleOrderByChange = (property: string) => {
        const isAsc = skills.orderBy === property && skills.sortOrder === "asc";
        skills.setSortOrder(isAsc ? "desc" : "asc");
        skills.setOrderBy(property);
    };

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);


    if (skills.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={[
                    {
                        label: "ID",
                        isSortable: false,
                    },
                    {
                        key: "Name",
                        label: "Name",
                        isSortable: true,
                    },
                    {
                        key: "CategoryName",
                        label: "Category",
                        isSortable: true,
                    },
                    {
                        key: "TalentsCount",
                        label: `${talentTerminology} count`,
                        isSortable: true,
                    },
                    {
                        label: "",
                        isSortable: false
                    }
                ]}
                data={skills.skills}
                renderRow={skill => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{skill.id}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{skill.name}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{skill.skillCategoryName}</TableCell>
                        <FilterLinkCell
                            href={`${appPaths.talents.skill(skill.id)}?skillName=${skill.name}`}
                            count={skill.talentsCount}
                        />
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.skills.edit(skill.id)}>
                                <IconButton aria-label="edit" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={skills.setPageIndex}
                setPageSize={skills.setPageSize}
                pageSize={skills.pageSize}
                pageIndex={skills.pageIndex}
                totalCount={skills.totalCount}
                orderBy={skills.orderBy}
                sortOrder={skills.sortOrder}
                isLoading={skills.isLoading}
            />
        </TableCard>
    );
};

export default SkillsTable;
