import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { UpdateUserDto, UserSummaryDto, AddGiggedClientUserClaimDto, RemoveGiggedClientUserClaimDto } from "./models/user";

export const useUpdateUser = () => useAuthenticatedGigApiFetcher<UpdateUserDto, void, { userId: string, dto: UpdateUserDto }>("PUT",
    ({ userId, dto }) => ({
        path: `api/users/${userId}`,
        body: dto
    }));

export const useArchiveUser = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (userId) => ({
        path: `api/users/${userId}/actions/archive`,
    }));

export const useUnarchiveUser = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (userId) => ({
        path: `api/users/${userId}/actions/unarchive`,
    }));

export const useDeleteUser = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (userId) => ({
        path: `api/users/${userId}`,
    }));

export const useAddGiggedClientUserClaim = () => useAuthenticatedGigApiFetcher<AddGiggedClientUserClaimDto, void, { userId: string, dto: AddGiggedClientUserClaimDto }>("POST",
    ({ userId, dto }) => ({
        path: `api/users/${userId}/gigged-clients/${dto.giggedClientId}/claims/add`,
        body: dto
    }));

export const useRemoveGiggedClientUserClaim = () => useAuthenticatedGigApiFetcher<RemoveGiggedClientUserClaimDto, void, { userId: string, dto: RemoveGiggedClientUserClaimDto }>("DELETE",
    ({ userId, dto }) => ({
        path: `api/users/${userId}/gigged-clients/${dto.giggedClientId}/claims/remove`,
        body: dto
    }));

export type UseUserReturn<TUserId extends string | undefined> = TUserId extends undefined ?
    { user: undefined } :
    {
        user: UserSummaryDto
        updateUser: (dto: UpdateUserDto) => Promise<GigApiFetcherResponse<void>>
        archiveUser: () => Promise<GigApiFetcherResponse<void>>
        unarchiveUser: () => Promise<GigApiFetcherResponse<void>>
        deleteUser: () => Promise<GigApiFetcherResponse<void>>
        addGiggedClientUserClaim: (dto: AddGiggedClientUserClaimDto) => Promise<GigApiFetcherResponse<void>>
        removeGiggedClientUserClaim: (dto: RemoveGiggedClientUserClaimDto) => Promise<GigApiFetcherResponse<void>>
        isUpdatingUser: boolean
        isArchivingUser: boolean
        isUnarchivingUser: boolean
        isDeletingUser: boolean
        isLoading: boolean
        isAddingGiggedClientUserClaim: boolean
        isRemovingGiggedClientUserClaim: boolean
    }

export const useUser = <TUserId extends string | undefined>(userId: TUserId): UseUserReturn<TUserId> => {
    const url = `api/users/${userId}`;
    const apiCall = useGiggedApiSWR<UserSummaryDto>(url);

    const [updateUser, isUpdatingUser] = useUpdateUser();
    const [archiveUser, isArchivingUser] = useArchiveUser();
    const [unarchiveUser, isUnarchivingUser] = useUnarchiveUser();
    const [deleteUser, isDeletingUser] = useDeleteUser();
    const [addGiggedClientUserClaim, isAddingGiggedClientUserClaim] = useAddGiggedClientUserClaim();
    const [removeGiggedClientUserClaim, isRemovingGiggedClientUserClaim] = useRemoveGiggedClientUserClaim();

    if (userId === undefined) return { user: undefined } as UseUserReturn<TUserId>;

    return {
        user: apiCall.data,
        isLoading: apiCall.isLoading,
        error: apiCall.error,
        updateUser: async (dto: UpdateUserDto) => {
            const response = await updateUser({ userId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        archiveUser: async () => {
            const response = await archiveUser(userId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        unarchiveUser: async () => {
            const response = await unarchiveUser(userId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        deleteUser: async () => {
            const response = await deleteUser(userId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        addGiggedClientUserClaim: async (dto: AddGiggedClientUserClaimDto) => {
            const response = await addGiggedClientUserClaim({ userId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        removeGiggedClientUserClaim: async (dto: RemoveGiggedClientUserClaimDto) => {
            const response = await removeGiggedClientUserClaim({ userId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isUpdatingUser,
        isArchivingUser,
        isUnarchivingUser,
        isDeletingUser,
        isAddingGiggedClientUserClaim,
        isRemovingGiggedClientUserClaim,
    } as UseUserReturn<TUserId>;
};