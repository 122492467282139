import { useEffect } from "react";
import { Link } from "react-router-dom";
import { IconButton, TableCell } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useClients } from "../../api/clients";
import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import { useOrganizationConfig } from "../../api/organization";
import { useAppPaths } from "../../Routes";
import TableChipCell from "../Table/TableChipCell";
import formatDateString from "../../utils/formatDateString";

export type ClientsTableProps = {
    searchTerm?: string
    badgeIds: string[],
}

const ClientsTable = ({
    searchTerm,
    badgeIds
}: ClientsTableProps) => {
    const appPaths = useAppPaths();
    const clients = useClients();
    const { giggedClientTerminology, giggedClientTerminologyPlural, organizationConfig, gigDashboardDomain } = useOrganizationConfig();
    const { setSearchTerm, setBadgeIds } = clients;

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    useEffect(() => {
        setBadgeIds(badgeIds);
    }, [setBadgeIds, badgeIds]);

    const handleOrderByChange = (property: string) => {
        const isAsc = clients.orderBy === property && clients.sortOrder === "asc";
        clients.setSortOrder(isAsc ? "desc" : "asc");
        clients.setOrderBy(property);
    };

    const headers = [
        {
            label: "ID",
            isSortable: false,
        },
        {
            key: "Name",
            label: organizationConfig?.hideGiggedClientCompanyInfo ? `${giggedClientTerminology} name` : "Display name",
            isSortable: true,
        },
    ];

    if (!organizationConfig?.hideGiggedClientCompanyInfo) {
        headers.push(
            {
                key: "CompanyName",
                label: "Company name",
                isSortable: true,
            },
        );
    }

    headers.push(
        {
            key: "UserEmail",
            label: "User email",
            isSortable: false,
        },
        {
            key: "UserName",
            label: "User full name",
            isSortable: false,
        },
    );


    if (organizationConfig?.isBadgesEnabled) {
        headers.push(
            {
                label: organizationConfig?.name === "Gigged.AI" ? "Verification status" : "Badge",
                isSortable: false
            }
        );
    }

    headers.push(
        {
            key: "CreatedAt",
            label: "Date created",
            isSortable: true
        },
        {
            label: "View profile",
            isSortable: false
        },
        {
            label: "",
            isSortable: false
        });

    if (clients.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={headers}
                data={clients.clients}
                renderRow={client => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{client.id}</TableCell>
                        <TableCell>
                            {client.name || "-"}
                        </TableCell>
                        {!organizationConfig?.hideGiggedClientCompanyInfo && <TableCell>{client.companyName || "-"}</TableCell>}
                        <TableCell sx={{ whiteSpace: "nowrap" }}><a className="hover:underline" href={`mailto:${client.userEmail}`}>{client.userEmail || "-"}</a></TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{client.userFirstName || "-"} {client.userLastName}</TableCell>
                        {organizationConfig?.isBadgesEnabled && <TableChipCell labels={[!client.badge ? "" : client.badge.badgeName]} />}
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateString(client.createdAt)}</TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                            <a href={`https://${gigDashboardDomain}/${giggedClientTerminologyPlural.toLowerCase().replace(/ /g, "-")}/${client.id}`} target="_blank">
                                <VisibilityIcon color="secondary" />
                            </a>
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.clients.edit(client.id)}>
                                <IconButton aria-label="edit" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={clients.setPageIndex}
                setPageSize={clients.setPageSize}
                pageSize={clients.pageSize}
                pageIndex={clients.pageIndex}
                totalCount={clients.totalCount}
                orderBy={clients.orderBy}
                sortOrder={clients.sortOrder}
                isLoading={clients.isLoading}
            />
        </TableCard>
    );
};

export default ClientsTable;