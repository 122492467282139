import { ApiGigSummary, ApiUpdateGigDto, ApiCreateGigDto, ApiEditableGigFields, ApiGigInvitesDto, ApiAcceptedInviteDto } from "./api/gig";

type AppGig<T> = Omit<T, "startDate"> & {
    startDate: Date
};

export type EditableGigFields = AppGig<ApiEditableGigFields>

export type CreateGigDto = AppGig<ApiCreateGigDto>
export type UpdateGigDto = AppGig<ApiUpdateGigDto>

export type GigSummary = AppGig<ApiGigSummary> & {
    expectedDurationLabel: string
}

export type GigDetails = Omit<GigSummary, "acceptedTalentName"> & {
    acceptedInvite?: ApiAcceptedInviteDto
}

export type GigInvitesDto = Omit<ApiGigInvitesDto, "gigInviteId"> & {
    id: string,
}

export const gigStatuses = {
    draft: 0,
    posted: 1,
    hired: 2,
    cancelled: 3,
    completed: 4,
};

export const gigStatusOptions = [
    { label: "Draft", value: 0 },
    { label: "Posted", value: 1 },
    { label: "Hired", value: 2 },
    { label: "Cancelled", value: 3 },
    { label: "Completed", value: 4 },
];

export const expectedDurationOptions = [
    { label: "1 hour", value: 0 },
    { label: "2 hours", value: 1 },
    { label: "3 hours", value: 2 },
    { label: "4 hours", value: 3 },
    { label: "5 hours", value: 4 },
    { label: "6 hours", value: 5 },
    { label: "7 hours", value: 6 },
    { label: "1 day", value: 7 },
    { label: "2 days", value: 8 },
    { label: "3 days", value: 9 },
    { label: "4 days", value: 10 },
    { label: "5 days", value: 11 },
];

export const gigInviteStatusOptions = [
    { label: "Sent", value: 0 },
    { label: "Accepted", value: 1 },
    { label: "Declined", value: 2 },
    { label: "Completed", value: 3 },
    { label: "Cancelled", value: 4 },
    { label: "On hold", value: 5 },
    { label: "Expired", value: 6 }
];

export const deliveryTypeOptions = [
    {label: "Face to face", value: 0},
    {label: "Virtual", value: 1}
];