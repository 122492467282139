import { LanguageDto } from "../api/models/api/language";
import gigApiFetcher from "./common/fetching";

export const fetchLanguages = async (query = "", accessToken: string | null): Promise<LanguageDto[]> => {
    const queryString = query ? `?searchTerm=${query}` : "";

    const response = await gigApiFetcher<never, LanguageDto[]>({
        path: `api/language${queryString}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const fetchLanguageOptions = async (query: string, accessToken: string | null) => {
    const languagesResponse = await fetchLanguages(query, accessToken);

    return languagesResponse
        .map(language => ({
            value: language.id,
            label: language.name,
        }));
};

export const fetchDocumentationLanguages = async (accessToken?: string | null): Promise<LanguageDto[]> => {
    const response = await gigApiFetcher<never, LanguageDto[]>({
        path: "api/documentation-languages",
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const fetchDocumentationLanguageOptions = async (accessToken?: string | null) => {
    const languagesResponse = await fetchDocumentationLanguages(accessToken);

    return languagesResponse.map(language => ({
        value: language.id,
        label: language.name,
    }));
};