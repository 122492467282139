import { Box, FormHelperText, MenuItem, Select } from "@mui/material";
import FormInputLabel from "../FormInputLabel";
import { Controller, UseFormReturn } from "react-hook-form";
import { ClientEditFormValues } from "./ClientEditFormValues";
import { OrganizationConfigDto } from "../../api/models/organization";
import { useBadges } from "../../api/badges";
import { badgeTypes } from "../../api/models/badge";

export type ClientBadgesFormDropdownProps = {
    organizationConfig: OrganizationConfigDto
    methods: UseFormReturn<ClientEditFormValues>
    isSubmitting: boolean
}

export const ClientBadgesFormDropdown = ({ 
    organizationConfig,
    methods,
    isSubmitting
}: ClientBadgesFormDropdownProps) => {
    const { data: badges } = useBadges({ badgeTypeIds: [badgeTypes.client] });

    return (
        <Box className="space-y-2 flex flex-col">
            <FormInputLabel>{organizationConfig?.name === "Gigged.AI" ? "Verification status" : "Badge"}</FormInputLabel>
            <Controller
                name="badgeId"
                control={methods.control}
                render={({ field: { onChange, value } }) =>
                    <Select value={value} onChange={onChange} variant="outlined" color="secondary" disabled={isSubmitting} displayEmpty error={!!methods.formState.errors.badgeId}>
                        {badges.length > 0 && badges.map(option => <MenuItem key={option?.badgeId} value={option?.badgeId}>{option?.name}</MenuItem>)}
                    </Select>
                }
            />
            {methods.formState.errors.badgeId && <FormHelperText className="!ml-4" error={!!methods.formState.errors.badgeId}>{methods.formState.errors.badgeId.message}</FormHelperText>}
        </Box>
    );
};

export default ClientBadgesFormDropdown;