import { useEffect } from "react";
import { IconButton, TableCell } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Link } from "react-router-dom";

import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import { useAppPaths } from "../../Routes";
import { useSkillCategories } from "../../api/skillCategories";
import formatDateOnlyString from "../../utils/formatDateString";

export type SkillCategoriesTableProps = {
    searchTerm?: string
}

const SkillCategoriesTable = ({ searchTerm }: SkillCategoriesTableProps) => {
    const skillCategories = useSkillCategories();
    const appPaths = useAppPaths();

    const setSearchTerm = skillCategories.setSearchTerm;

    const handleOrderByChange = (property: string) => {
        const isAsc = skillCategories.orderBy === property && skillCategories.sortOrder === "asc";
        skillCategories.setSortOrder(isAsc ? "desc" : "asc");
        skillCategories.setOrderBy(property);
    };

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);


    if (skillCategories.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={[
                    {
                        label: "ID",
                        isSortable: false,
                    },
                    {
                        key: "Name",
                        label: "Category name",
                        isSortable: true,
                    },
                    {
                        key: "SkillsCount",
                        label: "Workshop types count",
                        isSortable: true,
                    },
                    {
                        label: "Date created",
                        isSortable: false,
                    },
                    {
                        label: "",
                        isSortable: false
                    }
                ]}
                data={skillCategories.skillCategories}
                renderRow={skillCategory => (
                    <>

                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{skillCategory.id}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{skillCategory.name}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{skillCategory.skillsCount}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateOnlyString(skillCategory.createdAt)}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.skills.categories.edit(skillCategory.id)}>
                                <IconButton aria-label="edit" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={skillCategories.setPageIndex}
                setPageSize={skillCategories.setPageSize}
                pageSize={skillCategories.pageSize}
                pageIndex={skillCategories.pageIndex}
                totalCount={skillCategories.totalCount}
                orderBy={skillCategories.orderBy}
                sortOrder={skillCategories.sortOrder}
                isLoading={skillCategories.isLoading}
            />
        </TableCard>
    );
};

export default SkillCategoriesTable;
