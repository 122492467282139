import { useNavigate } from "react-router-dom";

import FormContainer from "../components/FormContainer";
import { useAppPaths } from "../Routes";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { capitalizeStrings } from "../utils/capitalizeString";
import { useSkills } from "../api/skills";
import { SkillCreateFormValues } from "../components/SkillCreateForm/SkillCreateFormValues";
import SkillCreateForm from "../components/SkillCreateForm/SkillCreateForm";

const SkillCreatePage = () => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { createSkill, isCreatingSkill } = useSkills();

    const onSubmit = async (values: SkillCreateFormValues): Promise<GigApiFetcherResponse<string>> => {
        const response = await createSkill({
            ...values,
            skillName: capitalizeStrings(values.skillName),
            skillCategoryId: values.skillCategoryId
        });

        if (response.success) {
            successToast("Insights workshop type successfully created.");
            navigate(appPaths.skills.index);
        }

        return response;
    };

    return (
        <FormContainer>
            <SkillCreateForm onSubmit={onSubmit} isSubmitting={isCreatingSkill} />
        </FormContainer>
    );
};

export default SkillCreatePage;
