import { useParams } from "react-router-dom";
import { useTalent } from "../api/talent";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { TalentEditForm, TalentEditFormValues } from "../components/TalentEditForm";
import { successToast } from "../toast";
import { useOrganizationConfig } from "../api/organization";
import { GigApiFetcherResponse } from "../api/common/fetching";
import TalentBadgesForm from "../components/TalentBadgesForm/TalentBadgesForm";
import { TalentBadgesFormValues } from "../components/TalentBadgesForm/TalentBadgesFormValues";
import TalentSkillsForm from "../components/TalentSkillsForm";
import TalentLanguagesForm from "../components/TalentLanguagesForm";

const TalentEditPage = () => {
    const { talentId } = useParams<{ talentId: string }>();
    const { updateTalent, isUpdatingTalent, isLoading, talent, addTalentBadge, isAddingTalentBadge, deleteTalentBadge, isDeletingTalentBadge } = useTalent(talentId as string);
    const { talentTerminology, organizationConfig } = useOrganizationConfig();

    const onSubmitTalentUpdate = async (values: TalentEditFormValues): Promise<GigApiFetcherResponse<void>> => {
        const response = await updateTalent({
            ...values,
            isQualiopiRequired: JSON.parse(values.isQualiopiRequired),
            insuranceExpiryDate: values.insuranceExpiryDate ?? null,
            contractSentDate: values.contractSentDate ?? null
        });

        if (response.success) {
            successToast(`${talentTerminology} successfully updated.`);
        }

        return response;
    };

    const onSubmitTalentBadge = async (values: TalentBadgesFormValues) => {
        const response = await addTalentBadge(values);

        if (response.success) {
            successToast(`${talentTerminology} badge successfully added.`);
        }

        return response;
    };

    const onDeleteTalentBadge = async (badgeId: string) => {
        const response = await deleteTalentBadge({ badgeId });

        if (response.success) {
            successToast(`${talentTerminology} badge successfully removed.`);
        }

        return response;
    };

    if (isLoading || !talentId) return <Loader />;

    return (
        <div className="space-y-4">
            {talent && (
                <>
                    <FormContainer>
                        <TalentEditForm
                            initialValues={talent}
                            onSubmit={onSubmitTalentUpdate}
                            isSubmitting={isUpdatingTalent}
                        />
                    </FormContainer>
                    <FormContainer>
                        <TalentSkillsForm talentId={talentId} />
                    </FormContainer>
                    <FormContainer>
                        <TalentLanguagesForm talentId={talentId} />
                    </FormContainer>
                    {organizationConfig?.isBadgesEnabled && (
                        <FormContainer>
                            <TalentBadgesForm
                                onSubmit={onSubmitTalentBadge}
                                onDelete={onDeleteTalentBadge}
                                initialValues={talent}
                                isSubmitting={isAddingTalentBadge || isDeletingTalentBadge || isLoading}
                            />
                        </FormContainer>
                    )}
                </>
            )}
        </div>
    );
};

export default TalentEditPage;