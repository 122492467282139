import gigApiFetcher from "./common/fetching";
import { WheelPositionDto } from "./models/api/wheelPosition";

export const fetchWheelPositionList = async (accessToken?: string | null): Promise<WheelPositionDto[]> => {
    const response = await gigApiFetcher<never, WheelPositionDto[]>({
        path: "api/wheel-positions",
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const fetchWheelPositionOptions = async (accessToken?: string | null) => {
    const wheelPositionResponse = await fetchWheelPositionList(accessToken);

    return wheelPositionResponse.map(wheelPosition => ({
        value: wheelPosition.id,
        label: wheelPosition.wheelPosition,
    }));
};