export const tomorrowAtMidnight = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(0, 0, 0, 0); 

    return date;
};

const getOrdinalSuffix = (day: number): string => {
    if (day % 100 >= 11 && day % 100 <= 13) return "th";
    return (day % 10) === 1 ? "st" : (day % 10) === 2 ? "nd" : (day % 10) === 3 ? "rd" : "th";
};

export const formatDate = (date: Date): string => {
    const formattedDay = `${date.getDate()}${getOrdinalSuffix(
        date.getDate()
    )}`;
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    const formattedMonth = months[date.getMonth()];
    const formattedYear = date.getFullYear();
    let formattedHours = date.getHours();
    const formattedMinutes =
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const ampm = formattedHours >= 12 ? "pm" : "am";
    formattedHours = formattedHours % 12 || 12; // Handle midnight (0) and noon (12)
    const formattedTime = `${formattedHours}:${formattedMinutes}${ampm}`;

    return `${formattedDay} ${formattedMonth}, ${formattedYear} at ${formattedTime}`;
};