import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Divider, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ClientMemberUser } from "../../api/models/client";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import ConfirmDialogBox from "../ConfirmDialogBox";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { successToast } from "../../toast";

export type MemberEmailDisplayProps = {
    user: ClientMemberUser
    clientName: string
    deleteUser: (userId: string) => Promise<GigApiFetcherResponse<void>>
    isDeleting: boolean
    numberOfUsers: number
}

const MemberEmailDisplay = ({
    user,
    clientName,
    deleteUser,
    isDeleting,
    numberOfUsers
}: MemberEmailDisplayProps) => {
    const handleDelete = async () => {
        const response = await deleteUser(user.userId);

        if (response.success) {
            successToast("User successfully removed.");
        }
        
        return response;
    };

    const [openConfirmDialog, confirmDialogProps] = useConfirmDialogBoxState({
        onConfirm: handleDelete
    });

    return (
        <>
            <Box key={user.email} sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", marginBottom: "15px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        <AccountCircleIcon fontSize="large" sx={{ marginRight: 1 }} />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography component="span" >
                                {user.firstName} {user.lastName}
                            </Typography>
                            <Typography component="span" >
                                <a className="hover:underline" href={`mailto:${user.email}`}>{user.email}</a>
                            </Typography>
                        </Box>
                    </Box>
                    {numberOfUsers > 1 && <CloseIcon onClick={openConfirmDialog} fontSize="medium" sx={{ cursor: "pointer", color: "gray", transition: "color 0.2s ease-in-out", ":hover": { color: "black" } }} /> }
                </Box>
                <Divider />
            </Box>
            <ConfirmDialogBox
                {...confirmDialogProps}
                message={`Are you sure you want to remove "${user.firstName} ${user.lastName}" from ${clientName}?`}
                confirmButtonText="Remove user"
                isProcessingConfirm={isDeleting}
            />
        </>
    );
};

export default MemberEmailDisplay;