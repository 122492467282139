import pluralizeString from "./utils/pluralizeString";

export const organizationSettingsTooltips = {
    addWhitelistedEmails: "Add any email domains that are to be whitelisted by your organisation in the correct format e.g. 'gigged.com'",
    overrideTerminologies: "Override the default terms used on the platform. For example, you might want to call a gig a project, a client a department, or a talent a freelancer.",
    theme: "Choose a colour theme for the platform from the pre-defined options in the dropdown below, or leave it as the default.",
    isEmailDomainWhitelisted: "By enabling email domain whitelisting, you can control which email domains can be used to sign up to the platform. Once enabled, you can add any email domains you wish to whitelist.",
    isTalentProfilesPrivate: (talentTerminology: string, giggedClientTerminology: string) =>
        `When enabled, ${pluralizeString(talentTerminology).toLowerCase()} won't be able to view other ${talentTerminology.toLowerCase()} profiles. ${pluralizeString(giggedClientTerminology)} will still be able to view all ${talentTerminology.toLowerCase()} profiles.`,
    isDisputesEnabled: "Toggle whether the disputes feature is enabled.",
};

export const dashboardOverviewTooltips = {
    usersTotal: "Total number of users in each role on the platform.",
    usersClientPercentage: (giggedClientTerminologyPlural: string) => `Percentage of all users who are ${giggedClientTerminologyPlural.toLowerCase()}.`,
    usersTalentPercentage: (talentTerminologyPlural: string) => `Percentage of all users who are ${talentTerminologyPlural.toLowerCase()}.`,
    total: (terminologyPlural: string) => `Total number of ${terminologyPlural.toLowerCase()} on the platform (all-time).`,
    created: (terminologyPlural: string) => `Total number of new ${terminologyPlural.toLowerCase()} registered within the specified time period, with percentage increase in brackets.`,
    lastActive: (terminologyPlural: string) => `Total number of ${terminologyPlural.toLowerCase()} active on the platform within the specified time period.`,
    locations: (terminologyPlural: string) => `Total number of ${terminologyPlural.toLowerCase()} per location on the platform.`,
    talentSkills: (talentTerminologyPlural: string) => `Total number of ${talentTerminologyPlural.toLowerCase()} on the platform with the specified skill.`,
    talentCompetencyLevels: (talentTerminologyPlural: string) => `Number of ${talentTerminologyPlural.toLowerCase()} on the platform with the specified competency level.`,
    skillsInCategories: "Total number of skills within the specified skill category.",
    skillCategoreies: "Total number of skill categories.",
    disputesTotal: "Total number of disputes raised (all-time).",
    disputesByType: "Total number of disputes of the specified dispute type.",
    reportsTotal: "Total number of reports created (all-time).",
    reportsCreated: "Total number of reports created within the specified time period.",
    reportsResolved: "Total number of reports resolved (all-time).",
    reportsByType: "Total number of reports per report type.",
    clientUsers: (giggedClientTerminologyPlural: string, giggedClientTerminology: string) => `Number of ${giggedClientTerminologyPlural.toLowerCase()} with the specified number of ${giggedClientTerminology.toLowerCase()} users.`,
    gigsTotals: (gigTerminologyPlural: string) => `Total number of ${gigTerminologyPlural.toLowerCase()} on the platform with the specified status.`,
    totalGigs: (gigTerminologyPlural: string) => `Total number of ${gigTerminologyPlural.toLowerCase()} on the platform (all-time).`,
    totalGigsPosted: (gigTerminologyPlural: string) => `Total number of ${gigTerminologyPlural.toLowerCase()} posted but not hired.`,
    totalGigsHired: (gigTerminologyPlural: string) => `Total number of ${gigTerminologyPlural.toLowerCase()} hired.`,
    totalGigsCompleted: (gigTerminologyPlural: string) => `Total number of ${gigTerminologyPlural.toLowerCase()} completed.`,
    totalGigsCancelled: (gigTerminologyPlural: string) => `Total number of ${gigTerminologyPlural.toLowerCase()} cancelled.`,
    totalGigsUptaken: (gigTerminologyPlural: string) => `Total number of active and completed gigs ${gigTerminologyPlural.toLowerCase()} which have been hired.`,
    totalGigsIdle: (gigTerminologyPlural: string) => `Total number of draft and posted ${gigTerminologyPlural.toLowerCase()} which have not been hired.`,
    activeGigs: (gigTerminologyPlural: string) => `Total number of active ${gigTerminologyPlural.toLowerCase()} on the platform of each status.`,
    gigsCreated: (gigTerminologyPlural: string) => `Total number of new ${gigTerminologyPlural.toLowerCase()} created within the specified time period, with percentage increase in brackets.`,
    gigsPosted: (gigTerminologyPlural: string) => `Total number of ${gigTerminologyPlural.toLowerCase()} posted within the specified time period.`,
    gigsCompleted: (gigTerminologyPlural: string) => `Total number of ${gigTerminologyPlural.toLowerCase()} completed within the specified time period.`,
    gigsCancelled: (gigTerminologyPlural: string) => `Total number of ${gigTerminologyPlural.toLowerCase()} cancelled within the specified time period.`,
    gigConversion: (gigTerminologyPlural: string) => `Percentage of all ${gigTerminologyPlural.toLowerCase()} which have been hired and completed.`,
    skillsTotal: (talentTerminologyPlural: string) => `Total number of different skills within your pool of ${talentTerminologyPlural.toLowerCase()}.`,
};

export const talentTooltips = {
    talentOrganizationAvailability: (talentTerminology: string) => `If the ${talentTerminology.toLowerCase()} is working on an internal assignment within your organisation, enter it here.`,
    talentAgency: (talentTerminology: string) => `If this ${talentTerminology.toLowerCase()} is part of an agency, select it here. You can also create a new agency from the agencies table.`,
    talentBadge: (talentTerminology: string) => `Add a badge to this ${talentTerminology.toLowerCase()} to show recognition for achievements.`,
    talentSkillCategory: "The category that this skill comes under. You can create new categories from the Categories table.",
};