import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useOrganizationConfig } from "../../api/organization";

export type ClientEditFormValues = {
    name: string
    aboutMe: string
    companyName?: string
    badgeId: string
    locationId: string | null
    localityId: string | null
};

export const useEditClientForm = (initialValues?: ClientEditFormValues, isBadgesEnabled?: boolean) => {
    const schema = buildClientValidationSchema(isBadgesEnabled);

    return useForm<ClientEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            ...initialValues,
            name: initialValues?.name ?? "",
            aboutMe: initialValues?.aboutMe ?? "",
            companyName: initialValues?.companyName ?? "",
            badgeId: initialValues?.badgeId ?? "",
            locationId: initialValues?.locationId ?? "",
        },
    });
};

const buildClientValidationSchema = (isBadgesEnabled?: boolean) => {
    const { giggedClientTerminology } = useOrganizationConfig();
    return yup
        .object({
            name: yup.string().required(`${giggedClientTerminology} name is required.`),
            aboutMe: yup.string().required("Overview is required."),
            companyName: yup.string().max(100, "Company name cannot be longer than 100 characters.").required("Company name is required."),
            badgeId: isBadgesEnabled ?
                yup.string().required("Badge is required.") :
                yup.string().optional(),
            locationId: yup.string().required("Country is required."),
            localityId: yup.string().required("Locality is required."),
        })
        .required();
};