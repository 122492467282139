import { Chip } from "@mui/material";
import { ApiTalentLanguagesDto } from "../api/models/talent";
import ChipContainer from "./TalentChipContainer";

export type TalentLanguageChipsProps = {
    languages?: ApiTalentLanguagesDto[],
    removeLanguage: (languageId: string) => void,
    isRemovingLanguage: boolean
}

const TalentLanguageChips = ({
    languages,
    removeLanguage,
    isRemovingLanguage,
}: TalentLanguageChipsProps) => {
    if (isRemovingLanguage) return (
        <p className="mt-2">Loading...</p>
    );

    return (
        <ChipContainer className="pt-6">
            {languages && languages
                .sort((a, b) => (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()))
                .map(talentLanguage => (
                    <Chip
                        key={`talent-language-${talentLanguage.languageId}`}
                        label={`${talentLanguage.languageName} (${talentLanguage.languageProficiencyName})`}
                        onDelete={() => removeLanguage(talentLanguage.languageId)} 
                        variant="outlined"
                        color="secondary"
                        sx={{ marginRight: 1, marginBottom: 1 }}
                    />
                    
                ))

            }
        </ChipContainer>
    );
};

export default TalentLanguageChips;