import { useMediaQuery, Box, Typography, useTheme } from "@mui/material";
import GigInvitesTablePage from "../components/GigInvitesTable";

const GigInvitesListPage = () => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Box sx={{ marginTop: "2rem", margin: (isLargeScreen ? 3 : 2) }}>
            <Typography variant="h4" gutterBottom>Invites</Typography>
            <GigInvitesTablePage />
        </Box>
    );
};

export default GigInvitesListPage;
