import { useEffect, useState } from "react";
import { mapFromApiTalentDetailsDto, mapToApiUpdateTalentDto } from "../mappers/talent";
import ApiError from "./common/apiError";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { ApiTalentDetailsDto, ApiUpdateTalentDto } from "./models/api/talent";
import { AddTalentBadgeDto, AdminAddTalentLanguageDto, DeleteTalentBadgeDto, TalentDetailsDto, UpdateTalentDto } from "./models/talent";

export const useUpdateTalent = () => useAuthenticatedGigApiFetcher<ApiUpdateTalentDto, void, { talentId: string, dto: UpdateTalentDto }>("PUT",
    ({ talentId, dto }) => ({
        path: `api/talent/${talentId}`,
        body: mapToApiUpdateTalentDto(dto)
    }));

export const useAddTalentBadge = () => useAuthenticatedGigApiFetcher<AddTalentBadgeDto, void, { talentId: string, dto: AddTalentBadgeDto }>("POST",
    ({ talentId, dto }) => ({
        path: `api/talent/${talentId}/badges/${dto.badgeId}`
    }));

export const useDeleteTalentBadge = () => useAuthenticatedGigApiFetcher<DeleteTalentBadgeDto, void, { talentId: string, dto: DeleteTalentBadgeDto }>("DELETE", ({ talentId, dto }) => ({
    path: `api/talent/${talentId}/badges/${dto.badgeId}`
}));

export const useAdminAddTalentSkill = () => useAuthenticatedGigApiFetcher<string, void, { talentId: string, skillId: string }>("POST",
    ({ talentId, skillId }) => ({
        path: `api/admin/skills/${skillId}/talent/${talentId}`
    }));

export const useAdminRemoveTalentSkill = () => useAuthenticatedGigApiFetcher<string, void, { talentId: string, skillId: string }>("DELETE",
    ({ talentId, skillId }) => ({
        path: `api/admin/skills/${skillId}/talent/${talentId}`
    }));

export const useAdminAddTalentLanguage = () => useAuthenticatedGigApiFetcher<AdminAddTalentLanguageDto, void, { languageId: string, languageProficiencyId: number, talentId: string }>("POST",
    ({ talentId, languageId, languageProficiencyId }) => ({
        path: `api/admin/language/${languageId}/talent/${talentId}`,
        body: { languageProficiencyId }
    })
);

export const useAdminRemoveTalentLanguage = () => useAuthenticatedGigApiFetcher<never, void, { talentId: string, languageId: string }>("DELETE",
    ({ languageId, talentId}) => ({
        path: `api/admin/language/${languageId}/talent/${talentId}`,
    })
);

export type UseTalentReturn<TTalentId extends string | undefined> = TTalentId extends undefined ?
    { talent: undefined } :
    {
        talent?: TalentDetailsDto
        isLoading: boolean
        error?: ApiError
        updateTalent: (dto: UpdateTalentDto) => Promise<GigApiFetcherResponse<void>>
        isUpdatingTalent: boolean
        addTalentBadge: (dto: AddTalentBadgeDto) => Promise<GigApiFetcherResponse<void>>
        isAddingTalentBadge: boolean
        deleteTalentBadge: (dto: AddTalentBadgeDto) => Promise<GigApiFetcherResponse<void>>
        isDeletingTalentBadge: boolean
        adminAddTalentSkill: (skillId: string) => Promise<GigApiFetcherResponse<void>>
        isAdminAddingTalentSkill: boolean
        adminRemoveTalentSkill: (skillId: string) => Promise<GigApiFetcherResponse<void>>
        isAdminRemovingTalentSkill: boolean
        adminAddTalentLanguage: (languageId: string, languageProficiencyId: number) => Promise<GigApiFetcherResponse<void>>
        isAdminAddingTalentLanguage: boolean
        adminRemoveTalentLanguage: (languageId: string) => Promise<GigApiFetcherResponse<void>>
        isAdminRemovingTalentLanguage: boolean
    }

export const useTalent = <TTalentId extends string | undefined>(talentId: TTalentId): UseTalentReturn<TTalentId> => {
    const url = `api/talents/${talentId}`;

    const [talent, setTalent] = useState<TalentDetailsDto | undefined>();

    const apiCall = useGiggedApiSWR<ApiTalentDetailsDto>(url);

    const [updateTalent, isUpdatingTalent] = useUpdateTalent();
    const [addTalentBadge, isAddingTalentBadge] = useAddTalentBadge();
    const [deleteTalentBadge, isDeletingTalentBadge] = useDeleteTalentBadge();
    const [adminAddTalentSkill, isAdminAddingTalentSkill] = useAdminAddTalentSkill();
    const [adminRemoveTalentSkill, isAdminRemovingTalentSkill] = useAdminRemoveTalentSkill();
    const [adminAddTalentLanguage, isAdminAddingTalentLanguage] = useAdminAddTalentLanguage();
    const [adminRemoveTalentLanguage, isAdminRemovingTalentLanguage] = useAdminRemoveTalentLanguage();

    useEffect(() => {
        if (apiCall.data === undefined) {
            setTalent(undefined);
            return;
        }

        setTalent(mapFromApiTalentDetailsDto(apiCall.data));
    }, [apiCall.data]);

    if (talentId === undefined) return { talent: undefined } as UseTalentReturn<TTalentId>;

    return {
        talent,
        isLoading: apiCall.isLoading,
        error: apiCall.error,
        updateTalent: async (dto: UpdateTalentDto) => {
            const response = await updateTalent({ talentId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isUpdatingTalent,
        addTalentBadge: async (dto: AddTalentBadgeDto) => {
            const response = await addTalentBadge({ talentId, dto });

            if (response.success) {
                apiCall.mutate();
            }
            return response;
        },
        isAddingTalentBadge,
        deleteTalentBadge: async (dto: DeleteTalentBadgeDto) => {
            const response = await deleteTalentBadge({ talentId, dto });

            if (response.success) {
                apiCall.mutate();
            }
            return response;
        },
        isDeletingTalentBadge,
        adminAddTalentSkill: async (skillId: string) => {
            const response = await adminAddTalentSkill({ talentId, skillId });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isAdminAddingTalentSkill,
        adminRemoveTalentSkill: async (skillId: string) => {
            const response = await adminRemoveTalentSkill({ talentId, skillId });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isAdminRemovingTalentSkill,
        adminAddTalentLanguage: async (languageId: string, languageProficiencyId: number) => {
            const response = await adminAddTalentLanguage({ languageId, languageProficiencyId, talentId });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isAdminAddingTalentLanguage,
        adminRemoveTalentLanguage: async (languageId: string) => {
            const response = await adminRemoveTalentLanguage({ talentId, languageId });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isAdminRemovingTalentLanguage,
    } as UseTalentReturn<TTalentId>;
};