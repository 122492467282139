import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useOrganizationConfig } from "../api/organization";
import { GigApiFetcherResponse } from "../api/common/fetching";
import SkillsSearchDropdown from "./SkillsSearchDropdown";
import { Button } from "@mui/material";
import ErrorList from "./ErrorList";
import { useTalent } from "../api/talent";
import FormCard from "./FormCard";
import TalentSkillChips from "./TalentSkillChips";


export const validationSchemaFields = {
    skillId: yup.string().required("Insights workshop type is required.")
};

export type TalentSkillsFormValues = {
    skillId: string;
};

const validationSchema = yup.object(validationSchemaFields);

export type TalentSkillsFormProps = {
    talentId: string
}

const TalentSkillsForm = ({ talentId }: TalentSkillsFormProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const { talent, adminAddTalentSkill, isAdminAddingTalentSkill, adminRemoveTalentSkill, isAdminRemovingTalentSkill } = useTalent(talentId);
    const { gigTerminology } = useOrganizationConfig();

    const handleRemoveSkill = async (skillId: string): Promise<GigApiFetcherResponse<void>> => {
        const response = await adminRemoveTalentSkill(skillId);

        return response;
    };

    const handleSubmit = async (values: TalentSkillsFormValues) => {
        setErrors([]);

        const existingSkill = talent?.skills.find(_ => _.skillId === values.skillId);

        if (existingSkill) {
            setErrors([`Workshop type "${existingSkill.skillName}" already added.`]);
            methods.reset();
            return;
        }

        setIsSubmitting(true);

        const response = await adminAddTalentSkill(values.skillId);

        setIsSubmitting(false);

        if (!response.success) {
            setErrors(response.error.userMessages);
        } else {
            methods.reset();
        }
    };

    const methods = useForm<TalentSkillsFormValues>({
        resolver: yupResolver(validationSchema)
    });

    if (!talent) return <>Loading...</>;

    return (
        <div>
            <FormCard title={`Insights ${gigTerminology.toLowerCase()} types`} withoutTitleUnderline width="100%">
                <form
                    onSubmit={methods.handleSubmit(handleSubmit)}
                    noValidate
                    className="flex gap-6 flex-wrap md:flex-nowrap md:items-start"
                >

                    <div className="flex gap-6 flex-wrap w-full md:flex-nowrap">
                        <div className="w-full">
                            <Controller
                                name="skillId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <SkillsSearchDropdown
                                        required
                                        label="Select skill"
                                        // tooltip={talentProfileEditTooltips.addSkills(gigTerminology)}
                                        value={value}
                                        disabled={isSubmitting}
                                        onChange={onChange}
                                        error={methods.formState.errors.skillId}
                                        disabledSkillIds={talent.skills.map(_ => _.skillId)}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <Button
                        color="secondary"
                        variant="outlined"
                        className="w-full !md:mt-auto md:w-auto md:self-end"
                        disabled={isAdminAddingTalentSkill}
                        type="submit"
                    >
                        Add
                    </Button>
                </form>

                <ErrorList errors={errors} />
            
                <TalentSkillChips skills={talent.skills} isRemovingSkill={isAdminRemovingTalentSkill} removeSkill={handleRemoveSkill} />

            </FormCard >

        </div >
    );
};

export default TalentSkillsForm;