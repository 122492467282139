import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { Box, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import ApiError from "../../api/common/apiError";
import ConfirmDialogBox from "../ConfirmDialogBox";
import FormTextInput from "../FormTextInput";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import { useAppPaths } from "../../Routes";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormErrors from "../FormErrors";
import { useNavigate } from "react-router-dom";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { DeleteForeverOutlined } from "@mui/icons-material";
import { SkillCategoryDetailsDto } from "../../api/models/skillCategory";
import { SkillCategoryEditFormValues, useSkillCategoryEditForm } from "./SkillCategoryEditFormValues";
import { useOrganizationConfig } from "../../api/organization";

export type SkillCategoryEditFormProps = {
    onSubmit: (values: SkillCategoryEditFormValues) => Promise<GigApiFetcherResponse<void>>
    onDelete: () => Promise<GigApiFetcherResponse<void>>
    initialValues: SkillCategoryDetailsDto
    isDeleting: boolean
    isSubmitting: boolean
}

const SkillCategoryEditForm = ({
    onSubmit,
    onDelete,
    initialValues,
    isSubmitting,
    isDeleting
}: SkillCategoryEditFormProps) => {
    const appPaths = useAppPaths();
    const isLargeScreen = useIsLargeScreen();
    const navigate = useNavigate();
    const theme = useTheme();
    const { talentTerminology, gigTerminologyPlural } = useOrganizationConfig();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const methods = useSkillCategoryEditForm({
        ...initialValues,
        skillCategoryName: initialValues.name
    });
    const { formState: { isDirty } } = methods;
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.skills.categories.index);
        }
    });
    const [openDeleteDialog, deleteDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleDeleteSkillCategory();
        }
    });

    useEffect(() => {
        methods.reset({
            ...initialValues,
            skillCategoryName: initialValues.name ?? "",
        });
    }, [methods.reset, initialValues]);

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.skills.categories.index);
        }
    };

    const handleFormSubmit = async (values: SkillCategoryEditFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) setSubmissionError(response.error);
    };

    const handleDeleteSkillCategory = async () => {
        setSubmissionError(undefined);

        const response = await onDelete();

        if (!response.success) setSubmissionError(response.error);
        else navigate(appPaths.skills.categories.index);
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleFormSubmit)}
                    noValidate
                >
                    <FormCard
                        onClose={handleCloseButton}
                        withoutTitleUnderline={true}
                        width="100%"
                        title="Edit Workshop Type Category">
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: theme.spacing(2),

                        }}>
                            <Box className="space-y-2">
                                <FormTextInput
                                    required
                                    name="skillCategoryName"
                                    label="Workshop type category name"
                                    disabled={isSubmitting}
                                />
                            </Box>
                        </Box>
                        <FormErrors messages={submissionError?.userMessages} />
                        <Box sx={
                            isLargeScreen ?
                                { display: "flex", justifyContent: "flex-end", marginTop: "2rem", gap: 2 } :
                                { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "2rem" }
                        }>
                            <LoadingButton disabled={isDeleting || isSubmitting} startIcon={<DeleteForeverOutlined sx={isDeleting ? { opacity: "26%" } : { opacity: "100%" }} />} loading={isDeleting} color="secondary" onClick={openDeleteDialog}>Delete</LoadingButton>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                loading={isSubmitting}
                                disabled={!isDirty}
                                sx={
                                    isLargeScreen ?
                                        { marginBottom: 0 } :
                                        { marginBottom: theme.spacing(2) }
                                }
                            >
                                Save changes
                            </LoadingButton>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
            <ConfirmDialogBox
                {...deleteDialogProps}
                message={"Are you sure you want to delete this category? This action cannot be undone."}
                warningMessage={
                    initialValues?.skills.length && initialValues?.skills.length > 0 ?
                        `There ${initialValues.skills.length === 1 ? "is" : "are"} ${initialValues.skills.length} ${initialValues.skills.length === 1 ? "skill" : "skills"} in this category that will be deleted if you proceed. These will also be removed from any ${talentTerminology.toLowerCase()} profiles or ${gigTerminologyPlural.toLowerCase()} where they are listed.`
                        : undefined
                }
                confirmButtonText="Delete"
            />
        </>
    );
};

export default SkillCategoryEditForm;