import { useOrganizationConfig } from "../../api/organization";
import { dashboardOverviewTooltips } from "../../tooltipsContent";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import DashboardSection from "../../components/Dashboard/DashboardSection";
import { ApiSkillStatisticsDto } from "../../api/models/dashboardStatistics/skillStatistics";
import DashboardSubSection from "../../components/Dashboard/DashboardSubSection";

export type DashboardSkillsSectionProps = {
    statistics?: ApiSkillStatisticsDto
}

const DashboardSkillsSection = ({
    statistics
}: DashboardSkillsSectionProps) => {
    const { talentTerminologyPlural } = useOrganizationConfig();

    return (
        <DashboardSection
            title="Insights workshop types"
        >
            <div className="flex flex-col space-y-2 w-full">
                <div className="flex flex-row space-x-2">
                    <DashboardCard
                        title="Total workshop types"
                        statistic={statistics?.skillsCounts}
                        tooltip={dashboardOverviewTooltips.skillsTotal(talentTerminologyPlural)}
                    />
                    <DashboardCard
                        title="Total workshop type categories"
                        statistic={statistics?.skillCategoriesCount}
                        tooltip={dashboardOverviewTooltips.skillCategoreies}
                    />
                </div>
                <DashboardSubSection
                    title="Workshop types by category"
                    tooltip={dashboardOverviewTooltips.skillsInCategories}
                >
                    {statistics?.skillsInCategoriesByCount && statistics?.skillsInCategoriesByCount.length ? (
                        <div className="flex flex-row space-x-2">
                            {statistics.skillsInCategoriesByCount.map(skill => (
                                <DashboardCard
                                    key={skill.skillCategoryCategoryName}
                                    title={skill.skillCategoryCategoryName}
                                    statistic={skill.skillCategorySkillsCount}
                                />
                            ))}
                        </div>
                    ) : (
                        <DashboardCard
                            title="No data"
                        />
                    )}
                </DashboardSubSection>
            </div>
        </DashboardSection>
    );
};

export default DashboardSkillsSection;