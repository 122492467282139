import TopNavMenu from "./TopNavMenu";
import SideNavMenu, { sideNavWidth } from "./SideNavMenu";
import { Disclosure } from "@headlessui/react";
import { useIsExtraLargeScreen } from "../../hooks/screenSizes";
import ClickAwayOverlay from "./ClickAwayOverlay";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Footer from "./Footer";
import { useOrganizationConfig } from "../../api/organization";
import Loader from "../Loader";

export type PageLayoutProps = {
    children: React.ReactNode,
}

const PageLayout = ({
    children,
}: PageLayoutProps) => {
    const isExtraLarge = useIsExtraLargeScreen();

    const { isLoading } = useOrganizationConfig();

    if (isLoading) return <Loader />;

    return (
        <Disclosure as="div" className="bg-white h-[100vh] flex flex-col" defaultOpen={isExtraLarge}>
            {({ open, close }) => {
                return (
                    <>
                        <TopNavMenu />
                        <ClickAwayOverlay isOpen={open} closeSideMenu={close} />
                        <div className="xl:flex bg-white">
                            <SideNavMenu close={close} />
                            <div
                                className="grow bg-white"
                                style={{ maxWidth: isExtraLarge ? `calc(100vw - 3rem - ${sideNavWidth}` : undefined }}
                            >
                                <AuthenticatedTemplate>
                                    {children}
                                </AuthenticatedTemplate>
                                <UnauthenticatedTemplate>
                                    <p>Loading...</p>
                                </UnauthenticatedTemplate>
                            </div>
                        </div>
                        <Footer />
                    </>
                );
            }}
        </Disclosure >
    );
};

export default PageLayout;