import { useParams } from "react-router-dom";

import { useClient } from "../api/client";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { ClientEditForm, ClientEditFormValues } from "../components/ClientEditForm";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useOrganizationConfig } from "../api/organization";

type ClientDetailsUpdate = Omit<ClientEditFormValues, "badgeId">
type ClientBadgeUpdate = Pick<ClientEditFormValues, "badgeId">

const ClientEditPage = () => {
    const { clientId } = useParams<{ clientId: string }>();
    const { updateClient, isUpdatingClient, isLoading, client, addClientBadge, isAddingClientBadge, reload } = useClient(clientId as string);
    const { giggedClientTerminology, organizationConfig } = useOrganizationConfig();

    const handleUpdates = async (values: ClientEditFormValues): Promise<GigApiFetcherResponse<void>[]> => {
        const { badgeId, ...clientDetails } = values;
        const detailsResponse = await updateClient(clientDetails as ClientDetailsUpdate);

        const responses: GigApiFetcherResponse<void>[] = [detailsResponse];

        if (organizationConfig?.isBadgesEnabled) {
            const badgeResponse = await addClientBadge({ badgeId } as ClientBadgeUpdate);

            responses.push(badgeResponse);

            if (badgeResponse.success && detailsResponse.success) {
                successToast(`${giggedClientTerminology} successfully updated.`);
            }
        } else {
            if (detailsResponse.success) {
                successToast(`${giggedClientTerminology} successfully updated.`);
                reload();
            }
        }


        return responses;
    };

    if (isLoading) return <Loader />;

    return (
        <FormContainer>
            {client && (
                <ClientEditForm
                    initialValues={client}
                    onSubmit={handleUpdates}
                    isSubmitting={isUpdatingClient || isLoading || isAddingClientBadge}
                />
            )}
        </FormContainer>
    );
};

export default ClientEditPage;
