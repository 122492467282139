import { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import useAccessToken from "../auth/useAccessToken";
import FormDropdown from "./FormDropdown";
import { FormSearchDropdownOption } from "./FormSearchDropdown/FormSearchDropdown";

export type ReusableDropdownProps = {
    value: string
    onChange: (value: string, option: FormSearchDropdownOption | null) => void
    fetchOptions: (accessToken?: string | null) => Promise<any[]>
    disabled?: boolean
    required?: boolean
    error?: FieldError
    tooltip?: string
    label: string
}

export const ReusableDropdown = ({
    value,
    onChange,
    fetchOptions,
    disabled,
    required,
    error,
    tooltip,
    label
}: ReusableDropdownProps) => {
    const accessToken = useAccessToken();
    const [allOptions, setAllOptions] = useState<FormSearchDropdownOption[]>([]);

    const fetchDropdownOptions = async () => {
        const response = await fetchOptions(accessToken);

        return response
            .map(option => ({
                value: option.value,
                label: option.label,
            }));
    };

    useEffect(() => {
        if (!accessToken) return;

        const loadAllOptions = async () => {
            const fetchedOptions = await fetchDropdownOptions();
            setAllOptions(fetchedOptions);
        };

        loadAllOptions();
    }, [accessToken]);

    if (allOptions.length < 1) return <div className="my-4">Loading...</div>;

    return (
        <FormDropdown
            required={required}
            label={label}
            tooltip={tooltip}
            options={allOptions}
            error={error}
            value={value}
            onChange={onChange}
            disabled={disabled}
        />
    );
};

export default ReusableDropdown;