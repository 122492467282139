import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { ApiGigInvitesDto } from "./models/api/gig";
import { mapFromApiGigInvitesDto } from "../mappers/gigInvites";
import { GigInvitesDto } from "./models/gig";

export type UseGigInvitesReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiGigInvitesDto>>, "data"> & {
        gigInvites: GigInvitesDto[]
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        gigId?: string
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
    };

export const useGigInvites = (gigId: string): UseGigInvitesReturn => {
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const url = "api/gig-invites?" +
        `gigId=${gigId || ""}&` +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&`;

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<ApiGigInvitesDto>>(url);

    const gigInvites = apiCall.data ? apiCall.data.items.map(mapFromApiGigInvitesDto) : [];

    return {
        ...apiCall,
        ...apiCall.data,
        gigInvites,
        setPageIndex,
        setPageSize,
    };
};