import { Routes, Route, Navigate, useParams } from "react-router-dom";
import ClientCreatePage from "./pages/ClientCreate";
import ClientsListPage from "./pages/ClientsList";
import ClientEditPage from "./pages/ClientEdit";
import GigsListPage from "./pages/GigsList";
import DashboardPage from "./pages/DashboardStatistics/DashboardPage";
import UserCreatePage from "./pages/UserCreate";
import UserEditPage from "./pages/UserEdit";
import UsersListPage from "./pages/UsersList";
import TalentsListPage from "./pages/TalentsList";
import FourOFourPage from "./pages/404";
import GigPage from "./pages/GigPage";
import GigCreatePage from "./pages/GigCreate";
import OrganizationSettingsPage from "./pages/OrganizationSettings";
import { useOrganizationConfig } from "./api/organization";
import SkillsListPage from "./pages/SkillsList";
import DisputesListPage from "./pages/DisputesList";
import DisputePage from "./pages/DisputePage";
import GigInvitesListPage from "./pages/GigInvitesList";
import ReportsListPage from "./pages/ReportsList";
import ReportPage from "./pages/ReportPage";
import TalentEditPage from "./pages/TalentEdit";
import AgenciesListPage from "./pages/AgenciesList";
import AgencyEditPage from "./pages/AgencyEdit";
import AgencyCreatePage from "./pages/AgencyCreate";
import BadgesListPage from "./pages/BadgesList";
import BadgeCreatePage from "./pages/BadgeCreate";
import BadgeEditPage from "./pages/BadgeEdit";
import SkillCategoriesListPage from "./pages/SkillCategoriesList";
import SkillCategoriesEditPage from "./pages/SkillCategoriesEdit";
import SkillCategoryCreatePage from "./pages/SkillCategoryCreate";
import SkillCreatePage from "./pages/SkillCreate";
import SkillEditPage from "./pages/SkillEdit";

export const fourOFour = "/404";

const appPaths = (gigTerminology: string, giggedTerminologyPlural: string, giggedClientTerminologyPlural: string, talentTerminologyPlural: string) => {
    const gig = gigTerminology.toLowerCase().replace(" ", "-");
    const gigPlural = giggedTerminologyPlural.toLowerCase().replace(" ", "-");
    const clientPlural = giggedClientTerminologyPlural.toLowerCase().replace(" ", "-");
    const talentPlural = talentTerminologyPlural.toLowerCase().replace(" ", "-");

    return ({
        index: "/",
        clients: {
            index: `/${clientPlural}`,
            create: `/${clientPlural}/create`,
            edit: (clientId: string) => `/${clientPlural}/${clientId}`,
        },
        gigs: {
            index: `/${gigPlural}`,
            create: `/${gigPlural}/create`,
            view: (gigId: string) => `/${gigPlural}/${gigId}`,
            edit: (gigId: string) => `/${gigPlural}/${gigId}/edit`,
            invites: (gigId: string) => `/${gigPlural}/${gigId}/invites`,
        },
        users: {
            index: "/users",
            create: "/users/create",
            edit: (userId: string) => `/users/${userId}`,
        },
        talents: {
            index: `/${talentPlural}`,
            skill: (skillId: string) => `/${talentPlural}/skill/${skillId}`,
            badge: (badgeId: string) => `/${talentPlural}/badge/${badgeId}`,
            edit: (talentId: string) => `/${talentPlural}/${talentId}`,
        },
        skills: {
            index: "/skills",
            create: "/skills/create",
            edit: (skillId: string) => `/skills/edit/${skillId}`,
            categories: {
                index: "/skills/categories",
                edit: (skillCategoryId: string) => `/skills/categories/${skillCategoryId}`,
                create: "/skills/categories/create"
            },
        },
        disputes: {
            index: "/disputes",
            view: (disputeId: string) => `/disputes/${disputeId}`
        },
        reports: {
            index: "/reports",
            view: (reportId: string) => `/reports/${reportId}`
        },
        agencies: {
            index: "/agencies",
            edit: (agencyId: string) => `/agencies/${agencyId}`,
            create: "/agencies/create"
        },
        badges: {
            index: "/badges",
            edit: (badgeId: string) => `/badges/${badgeId}`,
            create: "/badges/create",
        },
        competencyLevels: {
            index: "/competency-levels",
        },
        settings: "/settings",
        fourOFour,
    });
};

export const useAppPaths = () => {
    const { gigTerminology, gigTerminologyPlural, giggedClientTerminologyPlural, talentTerminologyPlural } = useOrganizationConfig();

    return appPaths(gigTerminology, gigTerminologyPlural, giggedClientTerminologyPlural, talentTerminologyPlural);
};

const AppRoutes: React.FC = () => {
    const routeAppPaths = useAppPaths();
    const { organizationConfig } = useOrganizationConfig();

    return (
        <Routes>
            <Route path={routeAppPaths.index} element={<DashboardPage />} />
            <Route path={routeAppPaths.clients.index} element={<ClientsListPage />} />
            <Route path={routeAppPaths.clients.create} element={<ClientCreatePage />} />
            <Route path={routeAppPaths.clients.edit(":clientId")} element={<ClientEditPage />} />
            <Route path={routeAppPaths.gigs.index} element={<GigsListPage />} />
            <Route path={routeAppPaths.gigs.create} element={<GigCreatePage />} />
            <Route path={routeAppPaths.gigs.view(":gigId")} element={<GigPage />} />
            <Route path={routeAppPaths.gigs.edit(":gigId")} element={<GigPage />} />
            <Route path={routeAppPaths.gigs.invites(":gigId")} element={<GigInvitesListPage />} />
            <Route path={routeAppPaths.users.index} element={<UsersListPage />} />
            <Route path={routeAppPaths.users.create} element={<UserCreatePage />} />
            <Route path={routeAppPaths.users.edit(":userId")} element={<UserEditPage />} />
            <Route path={routeAppPaths.talents.index} element={<TalentsListPage />} />
            <Route path={routeAppPaths.talents.skill(":skillIds")} element={<TalentsListPage />} />
            {organizationConfig?.isBadgesEnabled && <Route path={routeAppPaths.talents.badge(":badgeIds")} element={<TalentsListPage />} />}
            <Route path={routeAppPaths.talents.edit(":talentId")} element={<TalentEditPage />} />
            <Route path={routeAppPaths.skills.index} element={<SkillsListPage />} />
            <Route path={routeAppPaths.skills.create} element={<SkillCreatePage />} />
            <Route path={routeAppPaths.skills.edit(":skillId")} element={<SkillEditPage />} />
            <Route path={routeAppPaths.skills.categories.index} element={<SkillCategoriesListPage />} />
            <Route path={routeAppPaths.skills.categories.edit(":skillCategoryId")} element={<SkillCategoriesEditPage />} />
            <Route path={routeAppPaths.skills.categories.create} element={<SkillCategoryCreatePage />} />
            <Route path={routeAppPaths.settings} element={<OrganizationSettingsPage />} />

            {organizationConfig?.isDisputesEnabled && (
                <>
                    <Route path={routeAppPaths.disputes.index} element={<DisputesListPage />} />
                    <Route path={routeAppPaths.disputes.view(":disputeId")} element={<DisputePage />} />
                </>
            )}

            {organizationConfig?.isReportsEnabled && (
                <>
                    <Route path={routeAppPaths.reports.index} element={<ReportsListPage />} />
                    <Route path={routeAppPaths.reports.view(":reportId")} element={<ReportPage />} />
                </>
            )}

            {organizationConfig?.isTalentAgencyEnabled && (
                <>
                    <Route path={routeAppPaths.agencies.index} element={<AgenciesListPage />} />
                    <Route path={routeAppPaths.agencies.edit(":agencyId")} element={<AgencyEditPage />} />
                    <Route path={routeAppPaths.agencies.create} element={<AgencyCreatePage />} />
                </>
            )}

            {organizationConfig?.isBadgesEnabled && (
                <>
                    <Route path={routeAppPaths.badges.index} element={<BadgesListPage />} />
                    <Route path={routeAppPaths.badges.edit(":badgeId")} element={<BadgeEditPage />} />
                    <Route path={routeAppPaths.badges.create} element={<BadgeCreatePage />} />
                </>
            )}

            <Route path={routeAppPaths.fourOFour} element={<FourOFourPage />} />
            <Route path="*" element={<Navigate to={routeAppPaths.index} replace />} />
        </Routes>
    );
};

export const useGigId = (): string => {
    const { gigId } = useParams();

    if (!gigId) throw new Error("No gig id param available.");

    return gigId;
};

export const useTalentId = (): string => {
    const { talentId } = useParams();

    if (!talentId) throw new Error("No talent id param available.");

    return talentId;
};

export const useDisputeId = (): string => {
    const { disputeId } = useParams();

    if (!disputeId) throw new Error("No dispute id param available.");

    return disputeId;
};

export const useReportId = (): string => {
    const { reportId } = useParams();

    if (!reportId) throw new Error("No report id param available.");

    return reportId;
};

export const useAgencyId = (): string => {
    const { agencyId } = useParams<{ agencyId: string }>();

    if (!agencyId) throw new Error("No agency id param available.");

    return agencyId;
};

export const useBadgeId = (): string => {
    const { badgeId } = useParams<{ badgeId: string }>();

    if (!badgeId) throw new Error("No badge id param available.");

    return badgeId;
};

export const useSkillCategoryId = (): string => {
    const { skillCategoryId } = useParams<{ skillCategoryId: string }>();

    if (!skillCategoryId) throw new Error("No skill category id param available/");

    return skillCategoryId;
};

export const useSkillId = (): string => {
    const { skillId } = useParams<{ skillId: string }>();

    if (!skillId) throw new Error("No skill id param available/");

    return skillId;
};

export default AppRoutes;