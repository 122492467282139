import { useEffect, useState } from "react";
import { Fab, Box, Button, useTheme, useMediaQuery, Divider, ListItemText, MenuItem, MenuList } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import FilterIcon from "@mui/icons-material/FilterList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDebounce } from "use-debounce";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { useAppPaths } from "../../Routes";
import SearchBox from "../SearchBox";
import ClientsTable from "./ClientsTable";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { useOrganizationConfig } from "../../api/organization";
import DeletableChips from "../DeletableChips";
import { IconButtonMenu, MenuCheckboxGroup } from "../Menu";
import { badgeTypes } from "../../api/models/badge";
import { useBadges } from "../../api/badges";
import { FilterMenuOptionGroupOption } from "../Menu/MenuCheckboxGroup";
import { DeletableChip } from "../DeletableChips/DeletableChips";

export type BadgeOption = {
    value: string,
    label: string,
}

const ClientsTableWithSearch = () => {
    const appPaths = useAppPaths();
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();    
    const { giggedClientTerminology, organizationConfig } = useOrganizationConfig();

    const [searchTerm, setSearchTerm] = useState<string>(() => {
        return sessionStorage.getItem("searchTerm") || "";
    });

    const { data: badges } = organizationConfig?.isBadgesEnabled ? useBadges() : { data: [] };

    const [filterByBadgeIds, setFilterByBadgeIds] = useState<string[]>(() => {
        const cachedValue = sessionStorage.getItem("filterByBadgeIds");
        return cachedValue !== null ? JSON.parse(cachedValue) : [];
    });

    const [isBadgeOptionsLoaded, setIsBadgeOptionsLoaded] = useState(false);
    const [badgeOptions, setBadgeOptions] = useState<BadgeOption[]>([]);
    const [badgeFilterOptions, setBadgeFilterOptions] = useState<FilterMenuOptionGroupOption[]>([]);
    const [dynamicFilterChips, setDynamicFilterChips] = useState<DeletableChip[]>([]);

    const showFilterChipsInline = useMediaQuery("(min-width: 800px)");
    
    const [debounceSearchTerm] = useDebounce(searchTerm, 700);

    useEffect(() => {
        sessionStorage.setItem("searchTerm", searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        sessionStorage.setItem("filterByBadgeIds", JSON.stringify(filterByBadgeIds));
    }, [filterByBadgeIds]);

    useEffect(() => {
        if (!organizationConfig?.isBadgesEnabled || isBadgeOptionsLoaded || (badgeOptions.length === badges.length)) return;

        setBadgeOptions(badges
            .filter(badge => badge.typeId === badgeTypes.client)
            .map(badge => ({
                value: badge.badgeId,
                label: badge.name
            })));

        setIsBadgeOptionsLoaded(true);
    }, [badges, organizationConfig?.isBadgesEnabled]);

    useEffect(() => {
        if (!organizationConfig?.isBadgesEnabled) {
            setBadgeFilterOptions([]);
            return;
        }
        
        setBadgeFilterOptions(badgeOptions.map(badge => ({
            label: badge.label,
            value: isBadgeIdFiltered(badge.value),
            onChange: () => handleBadgeFilterChange(badge.value)
        })));
    }, [badgeOptions, filterByBadgeIds, organizationConfig?.isBadgesEnabled]);

    useEffect(() => {
        setDynamicFilterChips(badgeOptions.map(badge => ({
            label: badge.label,
            onDelete: () => removeFilterByBadgeId(badge.value),
            visible: isBadgeIdFiltered(badge.value),
        })));
    }, [badgeOptions, filterByBadgeIds]);

    const handleBadgeFilterChange = (badgeId: string) => {
        if (isBadgeIdFiltered(badgeId)) {
            removeFilterByBadgeId(badgeId);
        } else {
            addFilterByBadgeId(badgeId);
        }
    };

    const removeFilterByBadgeId = (badgeId: string) => {
        setFilterByBadgeIds(filterByBadgeIds.filter(id => id !== badgeId));
    };

    const addFilterByBadgeId = (badgeId: string) => {
        const index = filterByBadgeIds.indexOf(badgeId);
        if (index !== -1) {
            console.warn("Tried to add badge filter but badge was already being filtered", badgeId);
            return;
        }

        setFilterByBadgeIds([...filterByBadgeIds, badgeId]);
    };

    const isBadgeIdFiltered = (badgeId: string): boolean => {
        return filterByBadgeIds.find(id => id === badgeId) !== undefined;
    };

    const combinedFilterChips = [...dynamicFilterChips];

    const filterChips = (
        <DeletableChips
            chips={combinedFilterChips}
            sx={{
                color: `${theme.palette.tertiary.main} !important`,
                backgroundColor: `${theme.palette.primary.dark} !important`,
            }}
            deleteIcon={<CloseRoundedIcon />}
        />
    );

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                justifyContent: "space-between",
                "& > *:not(:first-of-type)": {
                    marginLeft: 1,
                },
            }}>
                <SearchBox
                    placeholder={`Search by ${giggedClientTerminology} name`}
                    onChange={setSearchTerm}
                    searchTerm={searchTerm}
                    sx={isLargeScreen ? { minWidth: "28rem", background: "#FFFFFF" } : { flexGrow: 1, background: "#FFFFFF" }}
                />
                {showFilterChipsInline && (
                    <>
                        {filterChips}
                        <Box sx={{ flexGrow: 1 }} />
                    </>
                )}
                {badges.length > 0 && (
                    <IconButtonMenu
                        id="filter-users"
                        icon={isLargeScreen ? <FilterIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" /> : <MoreVertIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" />}
    
                    >
                        <MenuList sx={{ width: "17rem", padding: 0 }}>
                            {organizationConfig?.isBadgesEnabled && (
                                <div>
                                    <MenuCheckboxGroup
                                        label={organizationConfig?.name === "Gigged.AI" ? "Verification status" : "Badge"}
                                        options={badgeFilterOptions}
                                    />
                                    <Divider />
                                </div>
                            )}
                            <MenuItem
                                onClick={() => {
                                    setFilterByBadgeIds([]);
                                }}
                            >
                                <ListItemText sx={{ textAlign: "right" }}>
                                    Clear filters
                                </ListItemText>
                            </MenuItem>
                        </MenuList>
                    </IconButtonMenu>

                )}

                {isLargeScreen && (
                    <Link to={appPaths.clients.create} tabIndex={-1} style={{ textDecoration: "none", outline: "none", marginLeft: "1.25rem" }}>
                        <Button
                            size="medium"
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                        >
                            Create
                        </Button>
                    </Link>
                )}
            </Box>
            {!showFilterChipsInline && (
                <Box sx={{
                    marginTop: "1.5rem"
                }}>
                    {filterChips}
                </Box>
            )}
            <Box sx={{ marginTop: "1.5rem" }}>
                <ClientsTable
                    searchTerm={debounceSearchTerm}
                    badgeIds={filterByBadgeIds}
                />
            </Box>
            {!isLargeScreen && (
                <Link to={appPaths.clients.create}>
                    <Fab
                        color="primary"
                        aria-label="create-client"
                        sx={{
                            position: "fixed",
                            bottom: theme.spacing(10),
                            right: theme.spacing(4),
                        }}
                    >
                        <AddIcon fontSize="large" />
                    </Fab>
                </Link>
            )}
        </>
    );
};
export default ClientsTableWithSearch;