import { DateTimePicker } from "@mui/x-date-pickers";

import FormInputLabel from "./FormInputLabel";
import FormTextInput from "./FormTextInput";

export type FormDateTimeInputProps = {
    label: string
    value: Date | null
    onChange: (value: Date | null) => void
    minDate?: Date
    maxDate?: Date
    required?: boolean
    hideHelpText?: boolean
    helperText?: string
    tooltip?: string
    name: string
}

const FormDateTimeInput = ({
    label,
    value,
    onChange,
    name,
    minDate,
    maxDate,
    required,
    tooltip
}: FormDateTimeInputProps) => {
    return (
        <div className="w-full space-y-2">
            <FormInputLabel tooltipText={tooltip} required={required}>{label}</FormInputLabel>
            <DateTimePicker
                inputFormat="dd/MM/yy HH:mm"
                minutesStep={15}
                ampmInClock
                value={value}
                onOpen={() => {
                    if (!value && minDate) {
                        onChange(minDate);
                    }
                }}
                onChange={onChange}
                minDate={minDate}
                maxDate={maxDate}
                renderInput={params => (
                    <div className="flex" ref={params.ref}>
                        <FormTextInput
                            required
                            name={name}
                            className="w-full"
                            ref={params.inputRef}
                            {...params.inputProps}
                        />
                        <span className="mt-[28px]">
                            {params.InputProps?.endAdornment}
                        </span>
                    </div>
                )}
            />
        </div>
    );
};

export default FormDateTimeInput;