export type EditableUserFields = {
    firstName: string
    lastName: string
    email: string
    isAdmin: boolean
}

export type CreateUserDto = EditableUserFields;

export type UserSummaryDto = EditableUserFields & {
    id: string
    isArchived: boolean
    giggedClientId?: string
    giggedClientName?: string
    talentId?: string
    memberSince: string
    lastActivityDate?: string
    hasAnyOngoingGigs: boolean
    userClaims: UserClaimDto[]
}

export type UpdateUserDto = {
    isAdmin: boolean
    firstName: string
    lastName: string
}

export type UserClaimDto = {
    claimTypeId: number
    claimTypeName: string
}

export type EditableGiggedClientUserClaimFields = {
    giggedClientId?: string
    claimTypeId: number
}

export type AddGiggedClientUserClaimDto = EditableGiggedClientUserClaimFields

export type RemoveGiggedClientUserClaimDto = EditableGiggedClientUserClaimFields

export const giggedClientUserClaimOptions = [
    { label: "Sales", value: 1 },
    { label: "Resources", value: 2 },
];

export const userClaimTypes = {
    default: 0, 
    sales: 1, 
    resources: 2, 
    ac: 3, 
    admin: 4, 
    global: 5
};