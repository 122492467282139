import { useEffect, useState } from "react";
import { mapFromApiGigDetails, mapToApiGig } from "../mappers/gig";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { ApiGigDetails, ApiUpdateGigDto } from "./models/api/gig";
import { GigDetails, GigSummary, UpdateGigDto } from "./models/gig";

export const useUpdateGig = () => useAuthenticatedGigApiFetcher<ApiUpdateGigDto, void, { gigId: string, dto: UpdateGigDto }>("PUT",
    ({ gigId, dto }) => ({
        path: `api/gigs/${gigId}`,
        body: mapToApiGig(dto),
    }));

export const usePutGigOnHold = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (gigId) => ({
        path: `api/gigs/${gigId}/actions/draft`,
    }));

export type UseGigReturn<TGigId extends string | undefined> = TGigId extends undefined ?
    { gig: undefined } :
    {
        gig?: GigDetails
        updateGig: (dto: UpdateGigDto) => Promise<GigApiFetcherResponse<void>>
        putGigOnHold: () => Promise<GigApiFetcherResponse<void>>
        isUpdatingGig: boolean
        isLoading: boolean
        isPuttingGigOnHold: boolean
    }

export const useGig = <TGigId extends string | undefined>(gigId?: TGigId): UseGigReturn<TGigId> => {
    const [gig, setGig] = useState<GigSummary | undefined>();
    const [updateGig, isUpdatingGig] = useUpdateGig();
    const [putGigOnHold, isPuttingGigOnHold] = usePutGigOnHold();
    
    const url = `api/gigs/${gigId}`;

    const apiCall = useGiggedApiSWR<ApiGigDetails>(url);

    useEffect(() => {
        if (apiCall.data === undefined) {
            setGig(undefined);
            return;
        } 
        
        setGig(mapFromApiGigDetails(apiCall.data));
    }, [apiCall.data]);

    if (gigId === undefined) return { gig: undefined } as UseGigReturn<TGigId>;

    return {
        ...apiCall,
        gig,
        isLoading: apiCall.isLoading,
        updateGig: async (dto: UpdateGigDto) => {
            const response = await updateGig({ gigId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        putGigOnHold: async () => {
            const response = await putGigOnHold(gigId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isUpdatingGig,
        isPuttingGigOnHold
    } as UseGigReturn<TGigId>;
};