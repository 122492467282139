import { ApiGigDetails } from "../api/models/api/gig";
import { expectedDurationOptions, EditableGigFields, GigDetails } from "../api/models/gig";

export const mapFromApiGigDetails = (apiDto: ApiGigDetails): GigDetails => ({
    ...apiDto,
    startDate: new Date(apiDto.startDate),
    expectedDurationLabel: expectedDurationOptions.find(_ => _.value === apiDto.expectedDurationId)?.label || "",
});

export const mapToApiGig = <TApi extends EditableGigFields>(apiDto?: TApi):
    TApi extends undefined ? undefined : Omit<TApi, "startDate"> & { startDate: string } => {
    if (apiDto === undefined) {
        return undefined as ReturnType<typeof mapToApiGig>;
    }

    return {
        ...apiDto,
        startDate: apiDto.startDate.toISOString(),
    } as ReturnType<typeof mapToApiGig>;
};