import { ApiTalentDetailsDto, ApiUpdateTalentDto } from "../api/models/api/talent";
import { TalentDetailsDto, UpdateTalentDto } from "../api/models/talent";

export const mapFromApiTalentDetailsDto = (apiDto: ApiTalentDetailsDto): TalentDetailsDto => ({
    ...apiDto,
    contractSentDate: apiDto.contractSentDate ? new Date(apiDto.contractSentDate) : null,
    insuranceExpiryDate: apiDto.insuranceExpiryDate ? new Date(apiDto.insuranceExpiryDate) : null,
});

export const mapToApiUpdateTalentDto = (dto: UpdateTalentDto): ApiUpdateTalentDto => ({
    ...dto,
    contractSentDate: dto.contractSentDate ? dto.contractSentDate.toISOString() : undefined,
    insuranceExpiryDate: dto.insuranceExpiryDate ? dto.insuranceExpiryDate.toISOString() : undefined
});