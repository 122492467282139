import { useState } from "react";
import { Button } from "@mui/material";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import LanguageDropdown from "./LanguageDropdown";
import FormDropdown from "./FormDropdown";
import { languageProficiencyOptions } from "../api/models/talent";
import ErrorList from "./ErrorList";
import { useTalent } from "../api/talent";
import TalentLanguageChips from "./TalentLanguageChips";
import FormCard from "./FormCard";

export type TalentLanguageFormValues = {
    languageId: string;
    languageProficiencyId: number;
};

export const validationSchemaFields = {
    languageId: yup.string().required("Language is required."),
    languageProficiencyId: yup.number().required("Language proficiency is required.")
};

const schema = yup.object(validationSchemaFields);

export const useTalentLanguageForm = () => {
    return useForm<TalentLanguageFormValues>({
        resolver: yupResolver(schema)
    });
};

export type TalentLanguageFormProps = {
    talentId: string
}

const TalentLanguageForm = ({ talentId }: TalentLanguageFormProps) => {
    const [errors, setErrors] = useState<string[]>([]);
    const methods = useTalentLanguageForm();
    const { talent, adminAddTalentLanguage, isAdminAddingTalentLanguage, adminRemoveTalentLanguage, isAdminRemovingTalentLanguage } = useTalent(talentId); 
    console.log({talentId})
    const handleSubmit = async (values: TalentLanguageFormValues) => {
        setErrors([]);

        const existingLanguage = talent?.languages?.find(language => language.languageId === values.languageId);

        if (existingLanguage) {
            setErrors([`Language "${existingLanguage.languageName}" already added.`]);
            methods.reset();
            return;
        }

        const response = await adminAddTalentLanguage(values.languageId, values.languageProficiencyId);

        if (!response.success) {
            setErrors(response.error.userMessages);
        } else {
            methods.reset();
            return;
        }
    };

    return (
        <div>
            <FormCard title={"Languages"} withoutTitleUnderline width="100%">


                <form
                    noValidate
                    className="flex gap-6 flex-wrap md:flex-nowrap md:items-start"
                    onSubmit={methods.handleSubmit(handleSubmit)}
                >
                    <div className="flex gap-6 flex-wrap w-full md:flex-nowrap">
                        <div className="w-full">
                            <Controller
                                name="languageId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <LanguageDropdown
                                        required
                                        // tooltip={talentProfileEditTooltips.industrialExperience(giggedClientTerminologyPlural)}
                                        label="Select language"
                                        error={methods.formState.errors.languageId}
                                        onChange={onChange}
                                        value={value}
                                        disabled={isAdminAddingTalentLanguage}
                                    />
                                )}
                            />
                        </div>
                        <div className="w-full">
                            <Controller
                                name="languageProficiencyId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDropdown
                                        required
                                        label="Select proficiency"
                                        options={languageProficiencyOptions}
                                        error={methods.formState.errors.languageProficiencyId}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isAdminAddingTalentLanguage}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <Button
                        color="secondary"
                        variant="outlined"
                        className="w-full md:mt-auto md:w-auto md:self-end"
                        disabled={isAdminAddingTalentLanguage}
                        type="submit"
                    >
                        Add
                    </Button>
                </form>

                <ErrorList errors={errors} />

                <TalentLanguageChips languages={talent?.languages} removeLanguage={adminRemoveTalentLanguage} isRemovingLanguage={isAdminRemovingTalentLanguage} />
            </FormCard>
        </div>
    );
};

export default TalentLanguageForm;