import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export type UserClaimFormValues = {
    claimTypeId: number;
};

const validationSchema = yup.object({
    claimTypeId: yup.number().required("Please select a claim type."),
});

export const useUserClaimForm = () => {
    return useForm<UserClaimFormValues>({
        resolver: yupResolver(validationSchema),
    });
};