import { Chip } from "@mui/material";

export type SplitChipProps = {
    primaryValue: string
    primaryBgColor: string
    primaryTextColor: string
    secondaryValue: string
    secondaryBgColor: string
    secondaryTextColor: string
}

const SplitChip = ({
    primaryValue,
    primaryBgColor,
    primaryTextColor,
    secondaryValue,
    secondaryBgColor,
    secondaryTextColor
}: SplitChipProps) => {
    return (
        <div
        >
            <Chip
                sx={{
                    "& .MuiChip-label": {
                        paddingRight: 6 + "px",
                        width: 42 + "px"
                    },
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    backgroundColor: primaryBgColor,
                    color: primaryTextColor,
                }}
                label={primaryValue}
            />
            <Chip
                sx={{
                    "& .MuiChip-label": {
                        paddingLeft: 6 + "px",
                    },
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    backgroundColor: secondaryBgColor,
                    color: secondaryTextColor,
                }}
                label={secondaryValue}
            />
        </div>
    );
};

export default SplitChip;