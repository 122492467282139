import gigApiFetcher from "./common/fetching";
import { CompetencyLevelDto } from "./models/api/competencyLevel";

export const fetchCompetencyLevelList = async (accessToken?: string | null): Promise<CompetencyLevelDto[]> => {
    const response = await gigApiFetcher<never, CompetencyLevelDto[]>({
        path: "api/competency-levels",
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const fetchCompetencyLevelOptions = async (accessToken?: string | null) => {
    const competencyLevelResponse = await fetchCompetencyLevelList(accessToken);
    
    return competencyLevelResponse
        .map(competencyLevel => ({
            value: competencyLevel.id,
            label: competencyLevel.name,
        }));
};