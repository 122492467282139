import { toast } from "react-toastify";

export const successToast = ( message:string ) => {
    toast(
        <p style={{ wordBreak: "break-word" }}>{message}</p>,
        { 
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.TOP_RIGHT
        });
};

export const errorToast = ( message: string ) => {
    toast(
        <p style={{ wordBreak: "break-word" }}>{message}</p>,
        { 
            type: toast.TYPE.ERROR,
            position: toast.POSITION.TOP_CENTER
        });
};