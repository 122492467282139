import WorkIcon from "@mui/icons-material/Work";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SettingsIcon from "@mui/icons-material/Settings";
import ShieldIcon from "@mui/icons-material/Shield";
import FlagIcon from "@mui/icons-material/Flag";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import BadgeIcon from "@mui/icons-material/MilitaryTech";

import { useAppPaths } from "../../Routes";
import { SideNavMenuItemConfig } from "./SideNavMenuItem";
import { useOrganizationConfig } from "../../api/organization";
import pluralizeString from "../../utils/pluralizeString";
import { Construction } from "@mui/icons-material";

export const buildClientNavItems = (): SideNavMenuItemConfig[] => {
    const appPaths = useAppPaths();
    const {
        organizationConfig,
        gigTerminology,
        giggedClientTerminology,
        talentTerminology,
    } = useOrganizationConfig();

    const navItems: SideNavMenuItemConfig[] = [];

    navItems.push(
        {
            name: "Dashboard",
            icon: <DashboardIcon fontSize="medium" />,
            href: appPaths.index,
            isCollection: false
        },
        {
            name: "Users",
            icon: <AccountCircleIcon fontSize="medium" />,
            href: appPaths.users.index,
            isCollection: false
        },
        {
            name: pluralizeString(gigTerminology),
            icon: <WorkIcon fontSize="medium" />,
            href: appPaths.gigs.index,
            isCollection: false
        },
        {
            name: pluralizeString(talentTerminology),
            icon: <AccountBoxIcon fontSize="medium" />,
            href: appPaths.talents.index,
            isCollection: false
        },
        {
            name: pluralizeString(giggedClientTerminology),
            icon: <ApartmentIcon fontSize="medium" />,
            href: appPaths.clients.index,
            isCollection: false
        }
    );

    if (organizationConfig?.isTalentAgencyEnabled) {
        navItems.push({
            name: "Agencies",
            icon: <GroupsIcon fontSize="medium" />,
            href: appPaths.agencies.index,
            isCollection: false
        });
    }

    navItems.push(
        {
            name: "Insights workshop types",
            icon: <Construction fontSize="medium" />,
            isCollection: true,
            subItems: [
                { name: "Workshop types", href: appPaths.skills.index },
                { name: "Categories", href: appPaths.skills.categories.index }
            ]
        }
    );

    if (organizationConfig?.isDisputesEnabled) {
        navItems.push({
            name: "Disputes",
            icon: <ShieldIcon fontSize="medium" />,
            href: appPaths.disputes.index,
            isCollection: false
        });
    }

    if (organizationConfig?.isReportsEnabled) {
        navItems.push({
            name: "Reports",
            icon: <FlagIcon fontSize="medium" />,
            href: appPaths.reports.index,
            isCollection: false
        });
    }

    if (organizationConfig?.isBadgesEnabled) {
        navItems.push({
            name: "Badges",
            icon: <BadgeIcon fontSize="medium" />,
            href: appPaths.badges.index,
            isCollection: false
        });
    }

    navItems.push({
        name: "Settings",
        icon: <SettingsIcon fontSize="medium" />,
        href: appPaths.settings,
        isCollection: false
    });

    return navItems;
};