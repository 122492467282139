import gigApiFetcher from "./common/fetching";
import { IndustrialExperienceDto } from "../api/models/industrialExperience";

export const fetchRatePackList = async (accessToken?: string | null): Promise<IndustrialExperienceDto[]> => {
    const response = await gigApiFetcher<never, IndustrialExperienceDto[]>({
        path: "api/rate-packs",
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const fetchRatePackOptions = async (accessToken?: string | null) => {
    const ratePackResponse = await fetchRatePackList(accessToken);

    return ratePackResponse
        .map(ratePack => ({
            value: ratePack.id,
            label: ratePack.name,
        }));
};