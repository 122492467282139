import { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useDebounce } from "use-debounce";

import SearchBox from "../SearchBox";
import TalentsTable from "./TalentsTable";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTalents } from "../../api/talents";
import DeletableChips from "../DeletableChips";
import { useAppPaths } from "../../Routes";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const TalentsTableWithSearch = () => {
    const appPaths = useAppPaths();
    const theme = useTheme();
    const navigate = useNavigate();
    const talents = useTalents();
    const isLargeScreen = useIsLargeScreen();

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debounceSearchTerm] = useDebounce(searchTerm, 700);
    const { skillIds } = useParams<{ skillIds: string }>();
    const { badgeIds } = useParams<{ badgeIds: string}>();
    const { competencyLevelIds } = useParams<{ competencyLevelIds: string}>();
    const [searchParams] = useSearchParams();
    const filteredSkillName = searchParams.get("skillName") || "";
    const filteredBadgeName = searchParams.get("badgeName") || "";
    const filteredCompetencyLevelName = searchParams.get("competencyLevelName") || "";
    const skillIdArray = skillIds?.split(",");
    const badgeIdArray = badgeIds?.split(",");
    const competencyLevelIdArray = competencyLevelIds?.split(",");
    const showFilterChipsInline = useMediaQuery("(min-width: 800px)");

    const filterChips = (filteredSkillName || filteredBadgeName) && (
        <DeletableChips
            chips={[
                {
                    label: `Filtered by: ${filteredSkillName}`,
                    onDelete: () => navigate(appPaths.skills.index),
                    visible: skillIds ? true : false
                },
                {
                    label: `Filtered by: ${filteredBadgeName}`,
                    onDelete: () => navigate(appPaths.badges.index),
                    visible: badgeIds ? true : false
                },
                {
                    label: `Filtered by: ${filteredCompetencyLevelName}`,
                    onDelete: () => navigate(appPaths.competencyLevels.index),
                    visible: competencyLevelIds ? true : false
                },
            ]}
            sx={{
                color: `${theme.palette.tertiary.main} !important`,
                backgroundColor: `${theme.palette.primary.dark} !important`,
                maxWidth: "18rem",
            }}
            deleteIcon={<CloseRoundedIcon />}
        />
    );

    useEffect(() => {
        if (!skillIdArray) return;

        talents.setSkillIds(skillIdArray);
    }, [talents.setSkillIds, skillIds]);

    useEffect(() => {
        if (!badgeIdArray) return;

        talents.setBadgeIds(badgeIdArray);
    }, [talents.setBadgeIds, badgeIds]);

    useEffect(() => {
        if (!competencyLevelIdArray) return;

        talents.setCompetencyLevelIds(competencyLevelIdArray);
    }, [talents.setCompetencyLevelIds, competencyLevelIds]);

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                justifyContent: "space-between",
                "& > *:not(:first-of-type)": {
                    marginLeft: 1,
                },
            }}>
                <SearchBox
                    placeholder="Search by Name"
                    onChange={setSearchTerm}
                    searchTerm={searchTerm}
                    sx={isLargeScreen ? { minWidth: "28rem", background: "#FFFFFF" } : { flexGrow: 1, background: "#FFFFFF" }}
                />
                {showFilterChipsInline && (
                    <>
                        {filterChips}
                        <Box sx={{ flexGrow: 1 }} />
                    </>
                )}
            </Box>

            {!showFilterChipsInline && (
                <Box sx={{
                    marginTop: "1.5rem"
                }}>
                    {filterChips}
                </Box>
            )}
            <Box sx={{ marginTop: "1.5rem" }}>
                <TalentsTable
                    searchTerm={debounceSearchTerm}
                    skillIds={skillIdArray}
                    badgeIds={badgeIdArray}
                    competencyLevelIds={competencyLevelIdArray}
                />
            </Box>
        </>
    );
};
export default TalentsTableWithSearch;