import { Box, Chip, Typography, useTheme } from "@mui/material";
import { deliveryTypeOptions, GigDetails, gigStatuses, GigSummary } from "../../api/models/gig";

import CreatedByDisplay from "../../components/CreatedByDisplay";
import FormCard from "../../components/FormCard";
import NameDisplayLink from "../../components/NameDisplayLink";
import StatusDisplay from "../../components/StatusDisplay";
import { useAppPaths } from "../../Routes";
import capitalizeString from "../../utils/capitalizeString";
import AssignedTalentDisplay from "./AssignedTalentDisplay";
import { GigStatus } from "../../pages/GigPage";
import EditButton from "../EditButton";
import Loader from "../../components/Loader";
import { successToast } from "../../toast";
import { useOrganizationConfig } from "../../api/organization";
import { useGigs } from "../../api/gigs";
import useConfirmDialogBoxState from "../../components/ConfirmDialogBox/useConfirmDialogBoxState";
import ConfirmDialogBox from "../../components/ConfirmDialogBox";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import pluralizeString from "../../utils/pluralizeString";
import { useGig } from "../../api/gig";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import formatDateString from "../../utils/formatDateString";

export type GigViewPageProps = {
    gig: GigDetails
    loading: boolean
    handleCloseButton: () => void
    handleEditGig: () => void
}

const GigViewPage = ({
    gig,
    loading,
    handleCloseButton,
    handleEditGig
}: GigViewPageProps) => {
    const theme = useTheme();
    const appPaths = useAppPaths();
    const { deleteGig, isDeletingGig, isLoading } = useGigs();
    const { putGigOnHold, isPuttingGigOnHold } = useGig(gig.id);
    const { gigTerminology } = useOrganizationConfig();
    const [openConfirmDeleteDialog, confirmDeleteDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => handleDeleteGig()
    });
    const [openHoldGigDialogState, confirmHoldGigDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => handlePutGigOnHold()
    });
    const navigate = useNavigate();
    const isLargeScreen = useIsLargeScreen();

    const gigStatus = Object.keys(gigStatuses).find(key => gigStatuses[key as GigStatus] === gig?.gigStatusId);
    const capitalizedGigStatus = gigStatus ? capitalizeString(gigStatus) : "";
    const gigIsEditable = gigStatus === "draft" || gigStatus === "posted";
    const hasCustomRequirements = gig.isDesignFeeTypeRequired || gig.isConsultancyFeeTypeRequired || gig.isPrepAndEvalFeeTypeRequired;

    const handleDeleteGig = async () => {
        const response = await deleteGig(gig.id);

        if (response.success) {
            successToast(`${gigTerminology} deleted successfully.`);
            navigate(appPaths.gigs.index);
        }

        return response;
    };

    const handlePutGigOnHold = async () => {
        const response = await putGigOnHold();

        if (response.success) {
            successToast(`${gigTerminology} put on hold successfully.`);
            return;
        }
    };

    if (!gig || loading) return <Loader />;

    return (
        <>
            <FormCard
                title={gig?.title}
                onClose={handleCloseButton}
            >
                <Box sx={{
                    "> *:not(:last-child)": {
                        marginBottom: theme.spacing(3),
                    }
                }}>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        ID: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.id}</Typography>
                    </Typography>
                    <NameDisplayLink
                        href={appPaths.clients.edit(gig.giggedClientId)}
                        name={gig.giggedClientName}
                    />
                    <CreatedByDisplay
                        createdBy={gig.createdByUserName}
                        createdAt={gig.createdAt}
                        updatedBy={gig.lastUpdatedByUserName}
                        updatedAt={gig.lastUpdatedAt}
                    />
                    <StatusDisplay status={capitalizedGigStatus} />
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Is confirmed?: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.isConfirmed ? "Yes" : "No"}</Typography>
                    </Typography>
                    <AssignedTalentDisplay talentName={gig.acceptedInvite?.talentName || "Not yet assigned"} />
                    <Typography fontWeight="bold" component="label">
                        Insights workshop type:
                    </Typography>
                    <div className="-mt-2 -mr-2 child:mr-2 child:mt-2 pt-2">
                        <Chip
                            key={gig.skills[0].id}
                            label={gig.skills[0].name}
                            disabled={loading}
                            variant="outlined"
                            color="secondary"
                        />
                    </div>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Client history?: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.hasClientHistory ? "Yes" : "No"}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Objectives: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.description || "None specified"}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Industry: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.industry?.name}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Delivery language: <Typography sx={{ marginLeft: "8px" }} component="span">{gig?.language?.name}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Delivery format: <Typography sx={{ marginLeft: "8px" }} component="span">{deliveryTypeOptions[gig?.deliveryTypeId].label}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Delivery language: <Typography sx={{ marginLeft: "8px" }} component="span">{gig?.language?.name}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Expected participants: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.expectedNumberParticipants}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Location: <Typography sx={{ marginLeft: "8px" }} component="span">{`${gig.location?.locality?.locality}, ${gig.location?.country}`}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Start date: <Typography sx={{ marginLeft: "8px" }} component="span">{formatDateString(gig.startDate.toISOString())}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Duration: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.expectedDurationLabel}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Audience executive?: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.isAudienceExecutive ? "Yes" : "No"}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Opportunity link: <Typography className="hover:underline hover:text-link" sx={{ marginLeft: "8px" }} component="span">
                            <a href={gig.opportunityLink} target="_blank">
                                {gig.opportunityLink || "Not specified"}
                            </a>
                        </Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Notes: <Typography sx={{ marginLeft: "8px" }} component="span">{gig.notes || "None specified"}</Typography>
                    </Typography>
                    <div className="flex flex-col">
                        <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                            Event Customisation Requirements:
                        </Typography>
                        {hasCustomRequirements ? (
                            <ol className="list-disc ml-4 gap-2">
                                {gig.isDesignFeeTypeRequired && (
                                    <li>
                                        <Typography fontWeight="normal" component="label" sx={{ display: "block" }}>
                                            Customisation of content and/or addition of new content <Typography sx={{ marginLeft: "4px" }} component="span">{`(${gig.designFeeTypeHours} hours)`}</Typography>
                                        </Typography>
                                    </li>
                                )}
                                {gig.isConsultancyFeeTypeRequired && (
                                    <li>
                                        <Typography fontWeight="normal" component="label" sx={{ display: "block" }}>
                                            Consultancy time required with client <Typography sx={{ marginLeft: "4px" }} component="span">{`(${gig.consultancyFeeTypeHours} hours)`}</Typography>
                                        </Typography>
                                    </li>
                                )}
                                {gig.isPrepAndEvalFeeTypeRequired && (
                                    <li>
                                        <Typography fontWeight="normal" component="label" sx={{ display: "block" }}>
                                            Additional prep & evaluation time required <Typography sx={{ marginLeft: "4px" }} component="span">{`(${gig.prepAndEvalFeeTypeHours} hours)`}</Typography>
                                        </Typography>
                                    </li>
                                )}
                            </ol>
                        ) : "None"}
                    </div>
                    <Box sx={
                        isLargeScreen ?
                            { display: "flex", marginTop: "auto", gap: 2, flexDirection: "row-reverse" } :
                            { display: "flex", flexDirection: "column", justifyContent: "flex-end", marginTop: "auto", gap: 1 }
                    }>
                        <EditButton
                            disabled={!gigIsEditable}
                            buttonText={`Edit ${gigTerminology.toLowerCase()}`}
                            disabledTooltip={`Only draft and posted ${pluralizeString(gigTerminology.toLowerCase())} can be edited.`}
                            onClick={handleEditGig}
                        />
                        {gig.gigStatusId === gigStatuses.posted && <LoadingButton type="button" onClick={openHoldGigDialogState} className="w-full md:w-auto md:mr-4" loading={isPuttingGigOnHold} disabled={isPuttingGigOnHold}>Put on hold</LoadingButton>}
                        <LoadingButton sx={isLargeScreen ? { marginRight: "auto" } : { marginRight: "unset" }} loading={isDeletingGig || isLoading} onClick={openConfirmDeleteDialog}>{`Delete ${gigTerminology.toLowerCase()}`}</LoadingButton>
                    </Box>
                </Box>
            </FormCard>
            <ConfirmDialogBox
                {...confirmDeleteDialogProps}
                message={`Are you sure you want to delete this ${gigTerminology.toLowerCase()}?`}
                confirmButtonText="Delete"
                isProcessingConfirm={isDeletingGig || isLoading}
            />
            <ConfirmDialogBox
                {...confirmHoldGigDialogProps}
                message={`Putting this ${gigTerminology.toLowerCase()} on hold will change its status to 'Draft'. Continue?`}
                confirmButtonText="Put on hold"
                cancelButtonText="Back"
                isProcessingConfirm={isPuttingGigOnHold || isLoading}
            />
        </>
    );
};

export default GigViewPage;