import { TableCell } from "@mui/material";

import Table from "../Table";
import TableCard from "../TableCard";
import { useOrganizationConfig } from "../../api/organization";
import formatDateTimeString from "../../utils/formatDateTimeString";
import Loader from "../Loader";
import { UseGigInvitesReturn } from "../../api/gigInvites";
import { gigInviteStatusOptions } from "../../api/models/gig";
import TableChipCell from "../Table/TableChipCell";

export type GigInvitesTableProps = {
    invites: UseGigInvitesReturn
};

const GigInvitesTable = ({
    invites
}: GigInvitesTableProps) => {
    const { talentTerminology } = useOrganizationConfig();
    
    const headers = [
        {
            label: "ID",
            isSortable: false
        },
        {
            label: `${talentTerminology} name`,
            isSortable: false,
        },
        {
            label: "Status",
            isSortable: false,
        },
        {
            key: "CreatedAt",
            label: "Date sent",
            isSortable: true,
        },
        {
            label: "Sent by",
            isSortable: false,
        },
        {
            label: "",
            isSortable: false,
            center: true,
        },
    ];

    if (invites.isLoading) return <Loader />;

    return (
        <TableCard>
            <Table
                headers={headers}
                data={invites.gigInvites}
                renderRow={invite => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{invite.id}</TableCell>
                        <TableCell sx={{whiteSpace: "nowrap" }}>{invite.talentName}</TableCell>
                        <TableChipCell labels={[gigInviteStatusOptions[invite.inviteStatusId].label]} />
                        <TableCell sx={{whiteSpace: "nowrap" }}>{formatDateTimeString(invite.dateSent)}</TableCell>
                        <TableCell sx={{whiteSpace: "nowrap" }}>{invite.sentBy.name}</TableCell>
                    </>
                )}
                setPageIndex={invites.setPageIndex}
                setPageSize={invites.setPageSize}
                pageSize={invites.pageSize}
                pageIndex={invites.pageIndex}
                totalCount={invites.totalCount}
                isLoading={invites.isLoading}
            />
        </TableCard>
    );
};

export default GigInvitesTable;