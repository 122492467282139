import { Box, useMediaQuery, useTheme } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import GigInvitesTable from "./GigInvitesTable";
import { useAppPaths, useGigId } from "../../Routes";
import DeletableChips from "../DeletableChips";
import { useNavigate } from "react-router-dom";
import { useOrganizationConfig } from "../../api/organization";
import { useGig } from "../../api/gig";
import { useGigInvites } from "../../api/gigInvites";

const GigInvitesTablePage = () => {
    const theme = useTheme();
    const showFilterChipsInline = useMediaQuery("(min-width: 800px)");
    const filteredGigId = useGigId();
    const invites = useGigInvites(filteredGigId);
    const { gig } = useGig(filteredGigId);

    const navigate = useNavigate();
    const appPaths = useAppPaths();
    const { gigTerminology } = useOrganizationConfig();

    const filterChips = gig?.title && (
        <DeletableChips
            chips={[
                {
                    label: `Filtered by ${gigTerminology.toLowerCase()}: ${gig.title}`,
                    onDelete: () => {
                        navigate(appPaths.gigs.index);
                    },
                    visible: filteredGigId ? true : false
                }
            ]}
            sx={{
                color: `${theme.palette.tertiary.main} !important`,
                backgroundColor: `${theme.palette.primary.dark} !important`,
                maxWidth: "18rem",
            }}
            deleteIcon={<CloseRoundedIcon />}
        />
    );

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                "> *:not(:first-of-type)": {
                    marginLeft: 1
                },
            }}>
                {showFilterChipsInline && !invites.isLoading && (
                    <>
                        {filterChips}
                        <Box sx={{ flexGrow: 1 }} />
                    </>
                )}
            </Box>
            {!showFilterChipsInline && !invites.isLoading && (
                <Box>
                    {filterChips}
                </Box>
            )}
            <Box sx={{ marginTop: "1.5rem" }}>
                <GigInvitesTable
                    invites={invites}
                />
            </Box>
        </>
    );
};

export default GigInvitesTablePage;