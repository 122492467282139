import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type SkillCategoryCreateFormValues = {
    skillCategoryName: string
};

export const useSkillCategoryForm = () => {
    const schema = skillCategoryCreateValidationSchema();

    return useForm<SkillCategoryCreateFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange"
    });
};

const skillCategoryCreateValidationSchema = () => {
    return yup
        .object({
            skillCategoryName: yup.string().required("Workshop type category name is required."),
        })
        .required();
};