import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type SkillEditFormValues = {
    skillName: string
    skillCategoryId: string
};

export const useSkillEditForm = (initialValues: SkillEditFormValues) => {
    const schema = skillEditValidationSchema();

    return useForm<SkillEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            skillName: initialValues.skillName,
            skillCategoryId: initialValues.skillCategoryId
        }
    });
};

const skillEditValidationSchema = () => {
    return yup
        .object({
            skillName: yup.string().required("Workshop type name is required."),
            skillCategoryId: yup.string().required("Workshop type category is required")
        })
        .required();
};